import {
    KeyboardReturn,
    CancelScheduleSend,
    SendAndArchive,
    CalendarMonth,
    AccessTimeRounded,
    PhoneEnabledRounded,
    LocationOnRounded,
} from '@mui/icons-material'
import { Box, Chip, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { MouseEvent, useContext } from 'react'
import { ReactComponent as NewStateIcon } from '../assets/icons/visit-new.svg'
import { ReactComponent as SentStateIcon } from '../assets/icons/visit-sent.svg'
import { ReactComponent as DoneStateIcon } from '../assets/icons/visit-done.svg'
import { ReactComponent as NurseIcon } from '../assets/icons/nurse.svg'
import { ReactComponent as InsuranceIcon } from '../assets/icons/insurance.svg'
import { MainVisitContext } from '../providers'
import { twMerge } from 'tailwind-merge'
import { useCustomSnackbar } from '../hooks'
import { uid } from 'react-uid'
import moment from 'moment'
import { styled, keyframes } from '@mui/system'
import { VisitCardComponentProps } from '../types/components'

export const VisitCard = (props: VisitCardComponentProps) => {
    const {
        id: patientId,
        isLoading,
        visitTime,
        patientName,
        visitLength,
        patientAddress,
        patientPhone,
        time,
        className,
        state,
        mainVisitData,
        onCardClick,
        hasPermissionToOpen,
        healthActionCodes,
        nurseName,
        baseNurseName,
        insuranceCompany,
        date,
        hasDate,
        showLessCodes = false,
    } = props

    const visitTimeFormatted = visitTime?.slice(0, 3).toLocaleLowerCase()
    const formattedPhone = patientPhone?.replace(
        /(\d{3})(\d{3})(\d{3})/,
        '$1 $2 $3',
    )
    const customSnackbar = useCustomSnackbar()

    const formattedAddress = patientAddress?.fullText?.replace(', CZ', '') || ''

    const navigate = useNavigate()
    const { setMainVisit } = useContext(MainVisitContext)

    const handleCardClick = (
        e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    ) => {
        if (hasPermissionToOpen) {
            navigate(`/visit-detail/${patientId}`)
            if (mainVisitData) {
                const formatedMainVisitData = {
                    id: mainVisitData?.id,
                    date: mainVisitData?.date,
                }
                const stringifiedData =
                    formatedMainVisitData &&
                    JSON.stringify(formatedMainVisitData)
                setMainVisit(formatedMainVisitData)
                localStorage.setItem('mainVisit', stringifiedData)
            }
            if (onCardClick) onCardClick(e)
            window.scroll({ top: 0, behavior: 'smooth' })
        } else {
            customSnackbar(
                'Nemáte oprávnění k otevření této návštěvy.',
                'warning',
            )
        }
    }

    const getActiveVisitIcon = () => {
        if (isLoading) {
            return (
                <Skeleton
                    animation='wave'
                    variant='rectangular'
                    width={18}
                    height={18}
                    className='rounded'
                />
            )
        }

        switch (state) {
            case 'Scheduled':
                return <CalendarMonth className='max-w-[18px] text-gray-500' />
            case 'Draft':
                return <NewStateIcon className='w-[18px]' />
            case 'Sent':
                return <SentStateIcon className='w-[18px]' />
            case 'Approved':
                return <DoneStateIcon className='w-[18px]' />
            case 'Returned':
                return <KeyboardReturn className='max-w-[18px] text-error-1' />
            case 'Reported':
                return <SendAndArchive className='max-w-[18px] text-success' />
            case 'NotExecuted':
                return (
                    <CancelScheduleSend className='max-w-[18px] text-gray-500' />
                )
            default:
                return null
        }
    }

    return (
        <Paper
            onClick={handleCardClick}
            className={twMerge(
                'relative h-full w-full rounded bg-white-reduced p-3 shadow-none',
                [!hasPermissionToOpen && 'bg-gray-300'],
                className,
            )}
        >
            <Stack className='gap-1 pb-4'>
                <Stack
                    direction='row'
                    className='justify-between'
                >
                    {isLoading ? (
                        <Skeleton
                            animation='wave'
                            className='text-xl font-medium'
                            width='60%'
                        />
                    ) : (
                        <Typography className='text-xl font-medium'>
                            {patientName}
                        </Typography>
                    )}
                    <Stack
                        className={twMerge(
                            'h-[1.625rem] w-[1.625rem] items-center justify-center rounded border border-solid border-orange-300',
                            isLoading && 'border-gray-400',
                        )}
                    >
                        {getActiveVisitIcon()}
                    </Stack>
                </Stack>
                {nurseName && (
                    <Stack
                        direction='row'
                        className='items-center gap-[6px]'
                    >
                        <Stack className='h-6 w-6 items-center justify-center'>
                            <NurseIcon className='h-5 w-5' />
                        </Stack>
                        <Typography className='text-sm text-gray-500'>
                            {nurseName}{' '}
                            {baseNurseName !== nurseName && (
                                <span className='truncate align-bottom'>
                                    ({baseNurseName})
                                </span>
                            )}
                        </Typography>

                        {hasDate && date && (
                            <Typography className='ml-auto text-sm text-gray-500'>
                                {moment(date, 'DD.MM.').format('DD.MM.')}
                            </Typography>
                        )}
                    </Stack>
                )}
                <Stack
                    direction='row'
                    className='gap-[6px]'
                >
                    <Stack
                        direction='row'
                        className='items-center gap-[6px]'
                    >
                        <Stack className='h-6 w-6 items-center justify-center'>
                            <InsuranceIcon className='h-5 w-5' />
                        </Stack>
                        <Typography className='text-sm text-gray-500'>
                            {insuranceCompany}
                        </Typography>
                    </Stack>
                    <Stack className='items-center justify-center'>
                        <Box className='h-2 w-px bg-gray-500' />
                    </Stack>
                    <Stack
                        direction='row'
                        className='items-center gap-1 text-gray-500'
                    >
                        <AccessTimeRounded className='text-inherit w-5' />
                        <Stack
                            direction='row'
                            className='items-center gap-[2px]'
                        >
                            <Typography className='text-sm text-gray-500'>
                                {time}
                            </Typography>
                            <Typography className='text-gray-500'>•</Typography>
                            <Typography className='text-sm text-gray-500'>
                                {visitTimeFormatted}
                            </Typography>
                            <Typography className='text-gray-500'>•</Typography>
                            <Typography className='text-sm text-gray-500'>
                                {visitLength}min
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction='row'
                className='gap-2 pb-4'
            >
                {isLoading ? (
                    Array.from(new Array(4)).map(() => (
                        <Skeleton
                            key={uid(Math.random())}
                            width={50}
                            height={22}
                            className='rounded'
                            variant='rectangular'
                            animation='wave'
                        />
                    ))
                ) : (
                    <>
                        {healthActionCodes
                            .slice(0, showLessCodes ? 3 : 4)
                            .map((code) => {
                                return (
                                    <div
                                        className='relative'
                                        key={`${code}-${Math.random()}`}
                                    >
                                        {parseInt(code) === 6323 &&
                                            (state === 'New' ||
                                                state === 'Scheduled' ||
                                                state === 'Draft' ||
                                                state === 'Returned') && (
                                                <>
                                                    <span className='absolute -right-[0.2rem] -top-[0.2rem] h-3 w-3 animate-ping rounded-full bg-error-2 outline outline-1 outline-error-1'></span>
                                                    <span className='absolute -right-[0.2rem] -top-[0.2rem] h-3 w-3 rounded-full bg-error-2 outline outline-1 outline-error-1'></span>
                                                </>
                                            )}
                                        <Chip
                                            label={code}
                                            color='primary'
                                            variant='outlined'
                                            size='small'
                                            sx={{
                                                '& .MuiChip-label': {
                                                    paddingX: '0',
                                                },
                                            }}
                                            className={twMerge(
                                                'rounded bg-white-reduced px-[13px] py-[7px] text-xs font-medium text-gray-700',
                                                [
                                                    !hasPermissionToOpen &&
                                                        'bg-gray-100 border-gray-600 text-gray-500',
                                                ],
                                            )}
                                        />
                                    </div>
                                )
                            })}
                        {healthActionCodes.length > (showLessCodes ? 3 : 4) && (
                            <Chip
                                label={'...'}
                                color='primary'
                                variant='outlined'
                                size='small'
                                sx={{
                                    '& .MuiChip-label': {
                                        paddingX: '0',
                                    },
                                }}
                                className={twMerge(
                                    'w-[1.625rem] rounded bg-white-reduced text-xs font-medium text-gray-700',
                                    [
                                        !hasPermissionToOpen &&
                                            'bg-gray-100 border-gray-600 text-gray-500',
                                    ],
                                )}
                            />
                        )}
                    </>
                )}
            </Stack>
            <Stack
                spacing={0.5}
                className='max-w-[70%]'
            >
                {isLoading ? (
                    <>
                        <Skeleton
                            animation='wave'
                            height={12}
                            width='70%'
                        />
                        <Skeleton
                            animation='wave'
                            height={12}
                            width='50%'
                        />
                    </>
                ) : (
                    <>
                        <Stack
                            direction='row'
                            className='gap-1'
                        >
                            <LocationOnRounded className='text-base text-gray-500' />
                            <Typography className='truncate text-xs text-gray-500'>
                                {formattedAddress}
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            className='gap-1'
                        >
                            <PhoneEnabledRounded className='text-base text-gray-500' />
                            <Typography className='truncate text-xs text-gray-500'>
                                {!!formattedPhone?.length
                                    ? formattedPhone
                                    : 'neuvedeno'}
                            </Typography>
                        </Stack>
                    </>
                )}
            </Stack>
        </Paper>
    )
}
