import {
    Box,
    FormControlLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Typography,
    colors,
} from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { wrap } from '../../../../utils'
import { useVoucherPlan } from '../../hooks'
import type { VoucherPlanHookProps, VoucherPlanProps } from '../../types'
import { ConditionalInput } from './ConditionalInput'
import { VoucherPlanText } from './VoucherPlanText'
import { VoucherCarePlan } from '../../../../types'
import { useUIDSeed } from 'react-uid'

const VoucherPlanView: FC<VoucherPlanProps> = (props) => {
    const {
        name,
        code,
        diagnose,
        contentText,
        instructionsText,
        carePlanIndex,
        visitForm,
        voucherIndex,
        isDescription,
        isDescriptionNotRequired,
        descriptionText,
        isLocation,
        locationText,
        isDrugApplication,
        drugApplicationText,
        isTypeOf6311,
        typeOf6311Text,
        isTargetPoint,
        targetPointText,
        targetPoints,
        visitTypeColor,
        canSave,
        canEdit,
        lastVisit,
    } = props

    const {
        formMethods: {
            control,
            getValues,
            watch,
            setValue,
            formState: { errors },
        },
    } = visitForm

    const uid = useUIDSeed()

    const voucherCarePlans = watch(
        `carePlans.${carePlanIndex}.voucherCarePlans`,
    )

    const currentDoneValue = getValues(
        `carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.done`,
    )

    const currentTypeOf6311Value = getValues(
        `carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.typeOf6311`,
    )

    const options = [
        { label: 'Zavedení', value: 'Introduction' },
        { label: 'Ukončení', value: 'Termination' },
    ]

    const handleChange = (event: SelectChangeEvent) => {
        setValue(
            `carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.targetPointID`,
            Number(event.target.value),
        )
    }

    const requiredFieldsValue = getValues('requiredFields')

    const isSameVoucher = useCallback(
        (voucher: VoucherCarePlan): boolean =>
            voucher.voucherHealthActionID ===
            voucherCarePlans?.[voucherIndex]?.voucherHealthActionID,
        [voucherCarePlans, voucherIndex],
    )

    const sameVoucher = useMemo(
        () =>
            lastVisit?.voucherCarePlans?.find((voucher) =>
                isSameVoucher(voucher),
            ),
        [isSameVoucher, lastVisit?.voucherCarePlans],
    )

    const getLastInput = useCallback(
        (
            inputName: keyof Pick<
                VoucherCarePlan,
                'description' | 'drugApplications' | 'location'
            >,
        ) => {
            const lastVoucher = sameVoucher

            return lastVoucher?.[inputName]
        },
        [sameVoucher],
    )

    const getLastInputData = useCallback(
        (
            inputName: keyof Pick<
                VoucherCarePlan,
                'description' | 'drugApplications' | 'location'
            >,
        ) => {
            const lastInput = getLastInput(inputName)

            return {
                lastInput,
                voucherCarePlansDate: lastVisit?.voucherCarePlansDate,
            }
        },
        [getLastInput, lastVisit?.voucherCarePlansDate],
    )

    return (
        <Box
            pb={2}
            mb={2}
            sx={{
                borderBottom: '1px solid #E5E7EB',
            }}
        >
            {!!voucherCarePlans &&
                (voucherCarePlans.length > 1 || !!diagnose) && (
                    <Typography
                        paddingBottom={1}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'medium',
                        }}
                    >
                        <Box
                            component={'span'}
                            width='13px'
                            height='13px'
                            display={'inline-block'}
                            marginRight={1}
                            borderRadius='50%'
                            sx={{
                                background: !!visitTypeColor
                                    ? visitTypeColor
                                    : '#333',
                            }}
                        />
                        {code} - {name}
                    </Typography>
                )}
            <Box paddingBottom={1}>
                <VoucherPlanText
                    title='Instrukce'
                    formattedText={instructionsText}
                />
                <FormControlLabel
                    sx={{
                        marginLeft: '0',
                        paddingBottom: '8px',
                        'span:last-of-type': {
                            fontSize: '14px',
                        },
                    }}
                    control={
                        <Controller
                            control={control}
                            name={`carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.done`}
                            defaultValue={currentDoneValue}
                            render={({ field: { onChange, value } }) => (
                                <Switch
                                    size='small'
                                    checked={value}
                                    disabled={!canEdit}
                                    onChange={(e) => onChange(e.target.checked)}
                                />
                            )}
                        />
                    }
                    label='provedeno'
                    labelPlacement='start'
                />
                <VoucherPlanText
                    title='Poznámka k výkonu z poukazu'
                    formattedText={contentText}
                />
            </Box>
            <Stack spacing={2}>
                {currentDoneValue ? (
                    <>
                        {isTargetPoint && (
                            <Stack spacing={1}>
                                <InputLabel
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {targetPointText}
                                </InputLabel>
                                <Controller
                                    control={control}
                                    rules={{
                                        required:
                                            isTargetPoint &&
                                            requiredFieldsValue,
                                    }}
                                    name={`carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.targetPointID`}
                                    render={({ field }) => {
                                        const targetPointError =
                                            visitForm.formMethods.formState
                                                .errors?.carePlans?.[
                                                carePlanIndex
                                            ]?.voucherCarePlans?.[voucherIndex]
                                                ?.targetPointID

                                        return (
                                            <>
                                                <Select
                                                    {...field}
                                                    variant='outlined'
                                                    size='small'
                                                    disabled={!canEdit}
                                                    onChange={handleChange}
                                                    value={
                                                        field.value?.toString() ??
                                                        ''
                                                    }
                                                    MenuProps={{
                                                        MenuListProps: {
                                                            sx: {
                                                                maxHeight:
                                                                    '300px',
                                                            },
                                                        },
                                                    }}
                                                    inputProps={{
                                                        sx: {
                                                            fontSize: '13px',
                                                            border:
                                                                targetPointError &&
                                                                !field.value
                                                                    ? '1px solid #e84a4a'
                                                                    : '',
                                                            backgroundColor:
                                                                '#f8f8f8',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        value={'0'}
                                                        sx={{
                                                            fontSize: '13px',
                                                        }}
                                                    >
                                                        <em>nevybráno</em>
                                                    </MenuItem>
                                                    {targetPoints?.map(
                                                        (targetPoint) => (
                                                            <MenuItem
                                                                key={uid(
                                                                    targetPoint.id,
                                                                )}
                                                                sx={{
                                                                    fontSize:
                                                                        '13px',
                                                                }}
                                                                value={
                                                                    targetPoint.id
                                                                }
                                                            >
                                                                {
                                                                    targetPoint.name
                                                                }
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </Select>

                                                {targetPointError &&
                                                    !field.value && (
                                                        <Typography className='!mt-0 text-xs text-error-2'>
                                                            Toto pole je povinné
                                                        </Typography>
                                                    )}
                                            </>
                                        )
                                    }}
                                />
                            </Stack>
                        )}
                        {isTypeOf6311}
                        {isTypeOf6311 && (
                            <Stack>
                                <InputLabel
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {typeOf6311Text}
                                </InputLabel>
                                <Controller
                                    rules={{
                                        required:
                                            isTypeOf6311 && requiredFieldsValue,
                                    }}
                                    control={control}
                                    name={`carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.typeOf6311`}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            defaultValue={
                                                currentTypeOf6311Value
                                            }
                                        >
                                            {options.map((option) => (
                                                <FormControlLabel
                                                    key={uid(option.value)}
                                                    value={option.value}
                                                    disabled={!canEdit}
                                                    componentsProps={{
                                                        typography: {
                                                            fontSize: '13px',
                                                        },
                                                    }}
                                                    control={
                                                        <Radio size='small' />
                                                    }
                                                    label={option.label}
                                                    labelPlacement='end'
                                                />
                                            ))}
                                        </RadioGroup>
                                    )}
                                />

                                {!!errors.carePlans?.[carePlanIndex]
                                    ?.voucherCarePlans?.[voucherIndex]
                                    ?.typeOf6311 && (
                                    <Typography
                                        fontSize='12px'
                                        color={colors.red[400]}
                                    >
                                        Toto pole je povinné
                                    </Typography>
                                )}
                            </Stack>
                        )}
                        <ConditionalInput
                            isDisabled={!canSave}
                            isInputShown={isLocation}
                            inputTitle={locationText}
                            copyNote={props?.content}
                            visitForm={visitForm}
                            carePlanIndex={carePlanIndex}
                            voucherIndex={voucherIndex}
                            inputControlName='location'
                            isRequired={requiredFieldsValue}
                            lastVoucherData={getLastInputData('location')}
                        />
                        <ConditionalInput
                            isDisabled={!canSave}
                            isInputShown={isDrugApplication}
                            inputTitle={drugApplicationText}
                            visitForm={visitForm}
                            copyNote={locationText ? '' : props?.content}
                            carePlanIndex={carePlanIndex}
                            voucherIndex={voucherIndex}
                            inputControlName='drugApplications'
                            isRequired={requiredFieldsValue}
                            lastVoucherData={getLastInputData(
                                'drugApplications',
                            )}
                        />

                        <ConditionalInput
                            isDisabled={!canSave}
                            isInputShown={isDescription}
                            inputTitle={descriptionText}
                            visitForm={visitForm}
                            copyNote={
                                isDrugApplication || isLocation
                                    ? ''
                                    : props?.content
                            }
                            carePlanIndex={carePlanIndex}
                            voucherIndex={voucherIndex}
                            inputControlName='description'
                            isRequired={
                                !isDescriptionNotRequired || requiredFieldsValue
                            }
                            lastVoucherData={getLastInputData('description')}
                        />
                    </>
                ) : (
                    <Box>
                        <InputLabel
                            sx={{
                                paddingBottom: '8px',
                                fontSize: '14px',
                                fontWeight: '500',
                            }}
                        >
                            Důvod neprovedení*
                        </InputLabel>
                        <Controller
                            control={control}
                            name={`carePlans.${carePlanIndex}.voucherCarePlans.${voucherIndex}.reasonNonDone`}
                            rules={{
                                required:
                                    !currentDoneValue && requiredFieldsValue,
                            }}
                            render={({ field }) => (
                                <OutlinedInput
                                    {...field}
                                    placeholder='zde zadejte informace o neprovedení'
                                    multiline
                                    rows={4}
                                    value={field.value || ''}
                                    disabled={!canEdit}
                                    error={
                                        errors.carePlans?.[carePlanIndex]
                                            ?.voucherCarePlans?.[voucherIndex]
                                            ?.reasonNonDone !== undefined
                                    }
                                    sx={{
                                        width: '100%',
                                        bgcolor: '#F8F8F8',
                                        fontSize: '14px',
                                        color: '#4B5563',
                                    }}
                                />
                            )}
                        />
                        {!!errors.carePlans?.[carePlanIndex]
                            ?.voucherCarePlans?.[voucherIndex]
                            ?.reasonNonDone && (
                            <Typography
                                fontSize='12px'
                                color={colors.red[400]}
                            >
                                Toto pole je povinné
                            </Typography>
                        )}
                    </Box>
                )}
            </Stack>
        </Box>
    )
}

export const VoucherPlan: FC<VoucherPlanHookProps> = wrap(
    VoucherPlanView,
    useVoucherPlan,
)
