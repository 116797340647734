import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AuthUser } from '../../providers/AuthProvider/types'
import {
    deleteUserProfilePhoto,
    getUserInfo,
    getUserProfilePhoto,
    postUserProfilePhoto,
} from './services'
import { ErrorResponse } from './types'
import { ProfilePhoto } from '../../types'
import { useAuth } from '../../providers/AuthProvider/hooks'
import { useCustomSnackbar } from '../../hooks'

export const useUserQuery = (token?: string) => {
    return useQuery<AuthUser, ErrorResponse>(
        ['user'],
        () => getUserInfo(token),
        {
            enabled: !!token && token !== '',
            retry: false,
        },
    )
}

export const useUserPhotoQuery = () => {
    const { token } = useAuth()
    return useQuery<ProfilePhoto, ErrorResponse>(
        ['user', 'profilePhoto'],
        () => getUserProfilePhoto(token),
        {
            enabled: !!token && token !== '',
            staleTime: 1000 * 60 * 5,
            retry: false,
        },
    )
}

export const useUserPhotoPostMutation = () => {
    const { token } = useAuth()
    const queryClient = useQueryClient()
    const snackbar = useCustomSnackbar()

    const { mutateAsync, isLoading } = useMutation(
        async (data: string) => await postUserProfilePhoto(data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', 'profilePhoto'])
                snackbar('Profilový obrázek nahrán')
            },
            onError: () => {
                snackbar(
                    'Profilový obrázek se nepodařilo nahrát. Zkuste to prosím znovu.',
                    'error',
                )
            },
        },
    )

    return { mutateAsync, isLoading }
}

export const useUserPhotoDeleteMutation = () => {
    const { token } = useAuth()
    const queryClient = useQueryClient()
    const snackbar = useCustomSnackbar()

    const { mutateAsync, isLoading } = useMutation(
        async () => await deleteUserProfilePhoto(token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', 'profilePhoto'])
                snackbar('Profilový obrázek smazán a nahrazen iniciály')
            },
            onError: () => {
                snackbar(
                    'Profilový obrázek se nepodařilo smazat. Zkuste to prosím znovu.',
                    'error',
                )
            },
        },
    )

    return { mutateAsync, isLoading }
}
