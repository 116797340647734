import { useMemo, useCallback } from 'react'
import type {
    BulletinFilterHookActivate,
    BulletinFilterItems,
    BulletinFilterType,
    FilterItemValue,
} from '../types'
import type { BulletinTag } from '../../../types/components'
import { singleSelectGroup } from '../constants/filters'

export const useBulletinFiltersActivate = (
    props: BulletinFilterHookActivate,
) => {
    const { bulletinFilters, setBulletinFilters, setBulletinQueryFilters } =
        props

    const handleFilterActivate = useCallback(
        ({
            item,
            group,
        }: {
            item: FilterItemValue
            group: BulletinFilterItems
        }) => {
            setBulletinFilters((currentData) => {
                return currentData.map((_group) => {
                    if (_group.type === group.type) {
                        const isSingleSelectGroup = singleSelectGroup.includes(
                            _group.type,
                        )

                        // If the group is a single select group
                        if (isSingleSelectGroup) {
                            return {
                                ..._group,
                                items: _group.items.map((_item) => ({
                                    ..._item,
                                    active:
                                        _item.name === item.name
                                            ? !_item.active
                                            : false,
                                })),
                            }
                        }

                        // For other groups like 'tag'
                        return {
                            ..._group,
                            items: _group.items.map((_item) => {
                                if (_item.name === item.name) {
                                    // Toggle active state for the item
                                    return { ..._item, active: !_item.active }
                                }
                                return _item
                            }),
                        }
                    }
                    return _group
                })
            })
        },
        [setBulletinFilters],
    )

    const extractedActiveFiltersWithType = useMemo(
        () =>
            bulletinFilters.flatMap((group) =>
                group.items
                    .filter(({ active }) => active)
                    .map((item) => ({ ...item, type: group.type })),
            ),
        [bulletinFilters],
    )

    const getActiveGroup = useCallback(
        (_type: BulletinFilterType) =>
            extractedActiveFiltersWithType.filter(({ type }) => type === _type),
        [extractedActiveFiltersWithType],
    )

    const handleActiveQueries = useCallback(() => {
        const hideAdmin = getActiveGroup('admin')[0]
        const readability = getActiveGroup('readability')[0]
        const archived = getActiveGroup('archived')[0]
        const activeTags = getActiveGroup('tag')
        const activeUsers = getActiveGroup('users')

        setBulletinQueryFilters({
            showUnread:
                typeof readability?.value === 'boolean'
                    ? readability?.value
                    : undefined,
            hideAdmin:
                typeof hideAdmin?.value === 'boolean'
                    ? hideAdmin?.value
                    : undefined,
            hideArchived:
                typeof archived?.value === 'boolean'
                    ? archived?.value
                    : undefined,
            bulletinTag: activeTags.length
                ? (activeTags.map(({ value }) => value) as BulletinTag[])
                : [],
            userId: activeUsers.map(({ value }) => Number(value)) as number[],
        })
    }, [getActiveGroup, setBulletinQueryFilters])

    return {
        handleFilterActivate,
        extractedActiveFiltersWithType,
        handleActiveQueries,
    }
}
