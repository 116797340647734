import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import type { DashboardProps } from '../types'
import { DashboardButton } from './DashboardButton'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import MedicationIcon from '@mui/icons-material/Medication'

import GroupIcon from '@mui/icons-material/Group'

export const OfficeDashboard = (props: DashboardProps) => {
    const { data, isLoading } = props
    const navigate = useNavigate()

    return (
        <Box
            component='section'
            className='pb-5 pt-2'
        >
            <Typography className='pb-5 font-medium text-gray-700'>
                Moje kancelář
            </Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={6}
                >
                    <DashboardButton
                        disabled={isLoading || !data?.returnedVisitsCount}
                        countNumber={data?.returnedVisitsCount}
                        countLoading={isLoading}
                        label='Vrácené návštěvy'
                        onClick={() => navigate('/visits-returned')}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <DashboardButton
                        disabled={isLoading || !data?.draftVisitsCount}
                        countNumber={data?.draftVisitsCount}
                        countLoading={isLoading}
                        label='Návštěvy ke zpracování'
                        onClick={() => navigate('/visits-inprogress')}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
// NOVÁ VERZE PŘEDPŘIPRAVENÁ

/*
export const OfficeDashboard = (props: DashboardProps) => {
    const { data, isLoading } = props
    const navigate = useNavigate()

    return (
        <Box
            component='section'
            className='pb-5 pt-2'
        >
            <Typography className='pb-5 font-medium text-gray-700'>
                Moje kancelář
            </Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={6}
                >
                    <DashboardButton
                        disabled={isLoading || !data?.returnedVisitsCount}
                        countNumber={data?.returnedVisitsCount}
                        countLoading={isLoading}
                        label='Vrácené návštěvy'
                        onClick={() => navigate('/visits-returned')}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Button
                        className='text-white flex h-full w-full cursor-pointer flex-col items-center justify-between gap-3 rounded border border-solid bg-orange-500 px-3  py-2 text-[#F8F8F8] hover:bg-orange-500'
                        onClick={() => console.log('přidat pacienta')}
                    >
                        <Typography className='mr-auto text-[0.875rem] lowercase text-[#F8F8F8] sm:text-[1rem]'>
                            Přidat pacienta
                        </Typography>
                        <PersonAddIcon className='ml-auto' />
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <DashboardButton
                        disabled={isLoading || !data?.returnedVisitsCount}
                        countNumber={data?.returnedVisitsCount}
                        countLoading={isLoading}
                        label='Návštěvy ke zpracování'
                        onClick={() => navigate('/visits-returned')}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Button
                        className='text-white flex h-full w-full cursor-pointer flex-col items-center justify-between gap-3 rounded border border-solid bg-[#F8F8F8] px-3  py-2 text-orange-500 hover:bg-[#F8F8F8]'
                        onClick={() => console.log('Přečíst medikaci')}
                    >
                        <Typography className='mr-auto text-[0.875rem] lowercase text-orange-500 sm:text-[1rem]'>
                            Přečíst medikaci
                        </Typography>
                        <MedicationIcon className='ml-auto' />
                    </Button>
                </Grid>
            </Grid>
            <Box className='mt-6 flex items-center justify-center '>
                <Button
                    type='button'
                    className='lowercase'
                    onClick={() => navigate('/patients')}
                >
                    zobrazit kartotéku
                    <GroupIcon className='ml-2' />
                </Button>
            </Box>
        </Box>
    )
}
*/
