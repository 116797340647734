import { useEffect, type MouseEvent } from 'react'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import {
    Box,
    Typography,
    Skeleton,
    IconButton,
    CircularProgress,
    Stack,
    Button,
    InputLabel,
    OutlinedInput,
} from '@mui/material'
import { PatientTabProps } from '../../types'
import { DocumentItem } from './DocumentItem'
import { useUIDSeed } from 'react-uid'
import { useOpenState } from '../../../../hooks'
import Modal from '../../../../components/Modal'

type DocumentProps = Pick<
    PatientTabProps,
    | 'patientDocumentsQuery'
    | 'handleFileUpload'
    | 'handleButtonClick'
    | 'fileInputRef'
    | 'uploadLoading'
    | 'description'
    | 'setDescription'
    | 'hasDescription'
    | 'setHasDescription'
    | 'handleSendUpload'
    | 'patientId'
>

export const PatientDocuments = (props: DocumentProps) => {
    const {
        patientDocumentsQuery,
        handleFileUpload,
        uploadLoading,
        handleButtonClick,
        fileInputRef,
        description,
        setDescription,
        hasDescription,
        handleSendUpload,
    } = props

    const uid = useUIDSeed()
    const { isOpen, stateSwitch, handleOpen } = useOpenState()

    useEffect(() => {
        if (!isOpen && hasDescription === false) {
            handleOpen()
        }
    }, [hasDescription, isOpen, handleOpen])

    return (
        <Box>
            <Typography className='pb-2 text-sm font-medium'>
                Dokumenty
            </Typography>
            {patientDocumentsQuery.isLoading ? (
                <Skeleton
                    variant='rounded'
                    width='100%'
                    height={100}
                    className='mb-4'
                />
            ) : (
                <Box className='relative mb-4 min-h-[100px] w-full rounded border border-solid border-gray-400 bg-white-reduced'>
                    {patientDocumentsQuery.data?.totalItems === 0 &&
                        !patientDocumentsQuery.isFetching &&
                        !uploadLoading && (
                            <Box className='absolute inset-0 flex items-center justify-center'>
                                <Typography className='text-sm'>
                                    Žádné dokumenty nebyly přiřazeny
                                </Typography>
                            </Box>
                        )}

                    <Box className='grid grid-cols-4 p-2 pb-0'>
                        {patientDocumentsQuery.data?.data?.map(
                            (document: any) => {
                                return (
                                    <DocumentItem
                                        key={uid(document.id)}
                                        patientId={props.patientId}
                                        {...document}
                                    />
                                )
                            },
                        )}
                        {uploadLoading && (
                            <Stack
                                className='mb-2 flex flex-col items-center rounded border-[1px] border-solid border-gray-400 pb-1 pt-3 text-center'
                                gap={1}
                            >
                                <CircularProgress />
                                <Typography className='mb-2 text-xs'>
                                    Nahrávání...
                                </Typography>
                            </Stack>
                        )}
                    </Box>

                    <div>
                        <IconButton
                            color='primary'
                            aria-label='upload picture'
                            component='span'
                            className='absolute bottom-0 right-0 m-2 bg-orange-300 text-white-reduced hover:bg-orange-300 hover:shadow-md'
                            size='small'
                            onClick={(e: MouseEvent) => handleButtonClick(e)}
                        >
                            <AddAPhotoIcon fontSize='small' />
                        </IconButton>
                        <input
                            hidden
                            accept='.png, .jpg, .jpeg, .pdf'
                            type='file'
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                        />
                    </div>

                    <Modal
                        open={isOpen}
                        handleClose={() => {
                            handleSendUpload(false)
                            stateSwitch()
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Stack>
                            <Typography
                                fontWeight={700}
                                mb={2}
                                textAlign={'center'}
                                color={'primary'}
                            >
                                Poznámka k dokumentu
                            </Typography>
                            <Typography
                                textAlign={'center'}
                                className='text-sm'
                            >
                                Můžete přidat poznámku k dokumentu
                            </Typography>
                            <Stack
                                gap={1}
                                className='mt-5'
                            >
                                <InputLabel className='text-sm'>
                                    Poznámka dokumentu
                                </InputLabel>
                                <OutlinedInput
                                    value={description}
                                    className='max-h-10 rounded border border-[#bfbfbf] bg-white-reduced py-0 text-sm font-medium'
                                    placeholder='Zde zadejte poznámku'
                                    sx={{
                                        paddingY: '6px',
                                    }}
                                    onChange={(event) =>
                                        setDescription(event.target.value)
                                    }
                                ></OutlinedInput>
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    className='mb-5 mt-5 bg-white-reduced'
                                    size='small'
                                    type='button'
                                    onClick={() => {
                                        handleSendUpload(true)
                                        stateSwitch()
                                    }}
                                >
                                    Odeslat
                                </Button>
                            </Stack>
                        </Stack>
                    </Modal>
                </Box>
            )}
        </Box>
    )
}
