import type { MouseEvent } from 'react'
import type {
    LoadingState,
    History,
    OpenStateProps,
    User,
    ProfilePhoto,
} from '..'
import type { ListNurseVisit } from '../../api/visit/types'
import type { MainVisitType } from '../../providers/MainVisitProvider'
import type {
    TrackLocationProps,
    VisitCollectionHookProps,
    VisitCollectionProps,
} from '../hooks'
import type {
    BoxProps,
    ModalProps,
    PaperProps,
    StackProps,
} from '@mui/material'
import { NavigateFunction } from 'react-router-dom'
import { UseQueryResult } from '@tanstack/react-query'
import { ErrorResponse } from '../../api/user/types'
import {
    BulletinCountResponse,
    BulletinMessageQueryResponse,
} from '../../api/notifications/types'
import { UseFormReturn } from 'react-hook-form'
import {
    BulletinPatientsSearchProps,
    BulletinUsersProps,
} from '../../components/Bulletin/types'
import { FileProps } from '../../components/Bulletin/BulletinUpload'

interface CollectionActions {
    shouldSetMainVisit: boolean
    cardWrapClick?: (e: MouseEvent) => void
}

export interface ContentLoaderProps {
    loadingText?: string
}

export interface ContentErrorProps {
    errorText?: string
}

export interface SortedEmptyProps {
    emptyText?: string
}

export enum EDIT_NOTIFICATIONS_ENUM {
    Archive = 'Archive',
    Delete = 'Delete',
    Read = 'Read',
    UnArchive = 'UnArchive',
}

type SortedContentProps = ContentLoaderProps & SortedEmptyProps

export type SortedVisitProps = VisitCollectionProps &
    LoadingState &
    Pick<
        VisitCollectionHookProps,
        'nearestElementAvailable' | 'orderAscending'
    > &
    CollectionActions &
    SortedContentProps

export type SortedCollectionProps = Omit<SortedVisitProps, 'hasEntries'>

export interface ProtectedRouteProps {
    outlet: JSX.Element
}

type VisitCardListProps = Pick<
    ListNurseVisit,
    | 'id'
    | 'visitTime'
    | 'patientName'
    | 'patientSex'
    | 'visitLength'
    | 'patientAddress'
    | 'patientPhone'
    | 'time'
    | 'experise'
    | 'state'
    | 'isFinish'
    | 'hasPermissionToOpen'
    | 'healthActionCodes'
    | 'insuranceCompany'
    | 'nurseName'
    | 'baseNurseName'
    | 'date'
> &
    LoadingState

export interface VisitCardView {
    mainVisitData?: MainVisitType
}

export type VisitCardProps = VisitCardListProps & VisitCardView

export type VisitCardComponentProps = VisitCardProps &
    Pick<PaperProps, 'className'> & {
        onCardClick?: (
            e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
        ) => void
        showLessCodes?: boolean
        hasDate?: boolean
    }

export type MonthPickerProps = Pick<
    History,
    | 'activeMonthDate'
    | 'handleDateChange'
    | 'handleAddMonth'
    | 'handleSubtractMonth'
>

export interface ChildreContainerProps {
    containerProps?: BoxProps
}
interface TrimmedUser {
    firstname?: string
    surname?: string
}
interface NavigationHook {
    anchorEl: HTMLElement | null
    open: boolean
    openSearch: OpenStateProps
    trimmedUser: TrimmedUser
    handleLogOut: () => void
    navigationOpen: (event: MouseEvent<HTMLElement>) => void
    navigationClose: () => void
    navigate: NavigateFunction
    user?: User
}

export type NavigationHookProps = Pick<
    NavigationProps,
    'containerProps' | 'children' | 'className'
>

export type NavigationProps = StackProps &
    ChildreContainerProps &
    NavigationHook & {
        location: TrackLocationProps
        userPhotoQuery: UseQueryResult<ProfilePhoto, ErrorResponse>
        notifications: BulletinCountResponse
    }

export type PatientSearchProps = OpenStateProps &
    Pick<NavigationProps, 'location'>

export type BulletinUser = Pick<User, 'id' | 'fullName'> & {
    role?: string
    icon?: string
}

export type BulletinFilterUser = Omit<BulletinUser, 'role'>

export type BulletinTag = 'Unknown' | 'Important' | 'Admin' | 'Fnol'

export enum SelectItemsCzech {
    'Unknown' = 'Nezařazeno',
    'Important' = 'Důležité',
    'Admin' = 'Admin',
    'Fnol' = 'FNOL',
}
export interface Bulletin {
    id: number
    user: BulletinUser
    dateCreated: Date
    title: string
    message: string
    tag: BulletinTag
    isReaded: boolean
    isAdmin: boolean
    isArchived?: boolean
}

export interface BulletinReaderCollection {
    totalItems: number
    data: BulletinUser[]
}
export interface BulletinMessageAttachments {
    dateUploaded: string
    description: string
    id: number
    name: string
    type: string
    isError?: boolean
}

export type urlLink = {
    name?: string
    id?: number
    urlAddress: string
}

export interface BulletinMessage extends Bulletin {
    isOwner?: boolean
    countOfUsersToRead: number
    readerUsers: BulletinReaderCollection
    urlLinks?: urlLink[]
    attachments: BulletinMessageAttachments[]
    selectedUsers?: BulletinUsersProps[]
    selectedPatients?: BulletinPatientsSearchProps[]
}

export type BulletinReadabilityProps = Partial<
    Pick<BulletinMessage, 'countOfUsersToRead' | 'readerUsers'>
>

export type BulletinCardProps = Bulletin &
    OpenStateProps &
    Pick<StackProps, 'className'> & {
        badgeColor?: string
        isLoading?: boolean
        notificationHidden?: boolean
        onCardClick: () => void
        stopListInvalidate?: boolean
        hideReadButtonDetail?: boolean
    }

export type UseBulletinCardHookProps = Omit<
    BulletinCardProps,
    'isOpen' | 'handleClose' | 'handleOpen' | 'onCardClick'
>

export interface BulletinCarouselProps extends LoadingState {
    bulletinList?: Bulletin[]
}

export interface BulletinCount {
    notReadedBulletinCount: number
}

export interface ModalComponentProps extends ModalProps {
    title?: string
    closeButton?: boolean
    handleClose: () => void
}

export type BulletinDetailModalProps = Pick<
    ModalComponentProps,
    'handleClose' | 'open'
> &
    Bulletin &
    Pick<
        BulletinCardProps,
        | 'stopListInvalidate'
        | 'badgeColor'
        | 'isLoading'
        | 'hideReadButtonDetail'
    >

export interface UseBulletinDetail
    extends Omit<BulletinDetailModalProps, 'stopListInvalidate'> {
    handleRead: (isReadedParam: boolean) => void
    bulletinMessageQuery: BulletinMessageQueryResponse
    bulletinFormModalState: OpenStateProps
}

export type BulletinDetailHook = Omit<
    UseBulletinDetail,
    'handleRead' | 'bulletinMessageQuery' | 'bulletinFormModalState'
>

export interface BulletinFormModalProps
    extends Pick<ModalComponentProps, 'handleClose' | 'open'> {
    submitMessage: (data: BulletinForm) => Promise<void>
    formMethods: UseFormReturn<BulletinForm>
    title?: string
    messageData?: Bulletin
    handleBulletinDelete: () => void
    bulletinDeleteLoading: boolean
    isFnol?: boolean
    isEdit?: boolean
}

export type BulletinFormModalHook = Omit<
    BulletinFormModalProps,
    | 'formMethods'
    | 'submitMessage'
    | 'bulletinDeleteLoading'
    | 'handleBulletinDelete'
>

export interface BulletinForm {
    title?: string | null
    tag: BulletinTag | string
    message: string
    attachments: FileProps[]
    selectedUsers?: BulletinUsersProps[]
    selectedPatients?: BulletinPatientsSearchProps[]
    urlLinks?: urlLink[]
}

export interface BulletinFormFormatted {
    title?: string | null
    tag: BulletinTag | string
    message?: string
    attachments: FileProps[]
    selectedUsers?: number[]
    selectedPatients?: number[]
    urlLinks?: string[]
}
export interface BulletinFormFnolFormatted {
    patientID: number
    title: string
    message: string
    media: string[]
}

export type BulletinTagProps = Pick<
    BulletinCardProps,
    'badgeColor' | 'tag' | 'isLoading' | 'isAdmin'
>
