import { Box, Button, OutlinedInput, Skeleton } from '@mui/material'
import { MouseEvent } from 'react'
import { useUpdateNoteMutation } from '../../../api'
import type { ImportantNoteItemProps } from '../types'

export const ImportantNoteItem = ({
    importantNote,
    isLoading,
    ...elementProps
}: ImportantNoteItemProps) => {
    const updateNoteMutation = useUpdateNoteMutation(
        importantNote.id,
        true,
        true,
    )

    const handleAcceptNote = async (e: MouseEvent) => {
        e.preventDefault()
        await updateNoteMutation.mutateAsync()
    }

    return (
        <Box
            mb={2}
            {...elementProps}
        >
            {isLoading ? (
                <>
                    <Skeleton
                        variant='rounded'
                        width='100%'
                        height='150px'
                        animation='wave'
                    />

                    <Skeleton
                        variant='rounded'
                        width='111px'
                        height='32px'
                        sx={{
                            background: 'primary.main',
                            boxShadow: 'none',
                        }}
                    />
                </>
            ) : (
                <>
                    <OutlinedInput
                        readOnly
                        fullWidth
                        multiline
                        rows={5}
                        size='small'
                        value={importantNote.text}
                        sx={{
                            marginBottom: '8px',
                            backgroundColor: '#f8f8f8',
                            fontSize: '14px',
                        }}
                    />
                    <Box textAlign='right'>
                        <Button
                            variant='contained'
                            size='small'
                            sx={{
                                color: '#f8f8f8',
                                textTransform: 'none',
                                boxShadow: 'none',
                            }}
                            onClick={handleAcceptNote}
                        >
                            Beru na vědomí
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    )
}
