import {
    Box,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material'
import { IconButton } from '@mui/material'
import React from 'react'
import DownloadFileButton from '../../../../components/DownloadFileButton'
import EditIcon from '@mui/icons-material/Edit'
import MenuIcon from '@mui/icons-material/Menu'
import RotateRight from '@mui/icons-material/RotateRight'

import classNames from 'classnames'

export const DocumentItemMenu = ({
    handleRotate,
    filename,
    base64String,
    rotation,
    stateSwitchFilename,
}: any) => {
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <Box
            className={classNames({
                'bottom-[1.25rem] left-5 top-auto rotate-[270deg]':
                    rotation === 90,
                'bottom-5 left-auto right-5 top-auto rotate-[180deg]':
                    rotation === 180,
                'bottom-5 left-auto right-[1.25rem] rotate-[90deg]':
                    rotation === 270,
                'absolute left-5 top-5 z-10 flex h-[43px] w-[43px] justify-start text-left':
                    true,
            })}
        >
            <IconButton
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                ref={anchorRef}
                onClick={handleToggle}
                className='ml-auto h-[43px] w-[43px] min-w-0 rounded-[100%] bg-orange-400 text-white-reduced'
            >
                <MenuIcon />
            </IconButton>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement='bottom-start'
                transition
                disablePortal
            >
                {({ TransitionProps, placement }: any) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start'
                                    ? 'left top'
                                    : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id='composition-menu'
                                    aria-labelledby='composition-button'
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem>
                                        <DownloadFileButton
                                            filename={filename}
                                            base64String={base64String}
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={stateSwitchFilename}
                                        className='text-base text-[#f8833b]'
                                    >
                                        Upravit{' '}
                                        <EditIcon className='ml-2 text-2xl' />
                                    </MenuItem>
                                    <MenuItem
                                        className='text-base text-[#f8833b]'
                                        onClick={(event) => {
                                            handleRotate()
                                            handleClose(event)
                                        }}
                                    >
                                        Otočit{' '}
                                        <RotateRight className='ml-2 text-2xl' />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

/*
 <Button
                id='demo-positioned-button'
                onClick={handleClick}
            >
                <IconButton className='ml-auto bg-orange-400 text-white-reduced'>
                    <MenuIcon />
                </IconButton>
            </Button>
            <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem>
                    <DownloadFileButton
                        filename={filename}
                        base64String={base64String}
                    />
                </MenuItem>
                <MenuItem>
                    <Button
                        type='button'
                        size='small'
                        onClick={stateSwitchFilename}
                    >
                        <EditIcon />
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        type='button'
                        size='small'
                        onClick={handleRotate}
                    >
                        <RotateRightIcon />
                    </Button>
                </MenuItem>
            </Menu>

*/
