import { useCallback, useMemo, useLayoutEffect } from 'react'
import type { BulletinFilterHooksProps, BulletinFilterType } from '../types'
import { singleSelectGroup } from '../constants/filters'

export const useBulletinFilterReset = ({
    bulletinFilters,
    setBulletinFilters,
}: BulletinFilterHooksProps) => {
    const groupWithEveryActive = useMemo(
        () =>
            bulletinFilters.find(({ type, items }) =>
                // type !== 'readability' &&
                items.every(({ active }) => active),
            ),
        [bulletinFilters],
    )

    const resetFilterAllActiveGroup = useCallback(
        (resetEverything = false) => {
            setBulletinFilters((currentData) =>
                currentData.map((group) => {
                    if (
                        resetEverything ||
                        group.type === groupWithEveryActive?.type
                    ) {
                        const isSingleSelectGroup = singleSelectGroup.includes(
                            group.type,
                        )

                        return {
                            ...group,
                            items: group.items.map((_item) => ({
                                ..._item,
                                active: isSingleSelectGroup
                                    ? false
                                    : _item.active,
                            })),
                        }
                    }
                    return group
                }),
            )
        },
        [groupWithEveryActive?.type, setBulletinFilters],
    )

    const resetFilterGroup = useCallback(
        (groupType: BulletinFilterType) => {
            setBulletinFilters((currentData) =>
                currentData.map((group) => {
                    if (group.type === groupType) {
                        return {
                            ...group,
                            items: group.items.map((_item) => ({
                                ..._item,
                                active: false,
                            })),
                        }
                    }
                    return group
                }),
            )
        },
        [setBulletinFilters],
    )

    useLayoutEffect(() => {
        resetFilterAllActiveGroup()
    }, [resetFilterAllActiveGroup])

    return { resetFilterGroup, resetFilterAllActiveGroup }
}
