import { memo, type FC } from 'react'
import type { BulletinFilterHookProps, BulletinFilterProps } from '../types'
import { Stack, Chip, Avatar } from '@mui/material'
import { Tune } from '@mui/icons-material'
import { wrap } from '../../../utils'
import { useBulletinFilters } from '../hooks'
import { BulletinFilterModal } from './BulletinFilterModal'
import { useUIDSeed } from 'react-uid'

const BulletinFiltersView: FC<BulletinFilterProps> = (props) => {
    const {
        isLoading,
        filterModalState,
        bulletinFilters,
        handleFilterActivate,
        resetFilterGroup,
        getSameFilterGroup,
        resetFilterAllActiveGroup,
        handleUseFilters,
        actualFilter,
        setDeleteTrigger,
    } = props

    const { handleOpen, isOpen, handleClose } = filterModalState

    const uid = useUIDSeed()
    return !isLoading ? (
        <Stack
            direction='row'
            className='mb-4 flex-wrap gap-1'
        >
            <BulletinFilterModal
                open={isOpen}
                handleUseFilters={handleUseFilters}
                bulletinFilters={bulletinFilters}
                resetFilterAllActiveGroup={resetFilterAllActiveGroup}
                handleFilterActivate={handleFilterActivate}
                resetFilterGroup={resetFilterGroup}
                {...filterModalState}
                handleClose={() => {
                    handleClose()
                }}
            />
            {actualFilter.map((item) => (
                <Chip
                    key={uid(item.name)}
                    color='primary'
                    variant='outlined'
                    avatar={
                        item.name?.split(' ').length > 1 || item.icon ? (
                            <Avatar
                                className='bg-orange-300 text-base'
                                src={item.icon || ''}
                                alt={item.name || ''}
                            >
                                {!item.icon?.trim() &&
                                    `${item.name?.split(' ')[0][0]}${
                                        item.name?.split(' ')[1][0]
                                    }`}
                            </Avatar>
                        ) : undefined
                    }
                    label={item.name}
                    onDelete={() => {
                        handleFilterActivate({
                            item,
                            group: getSameFilterGroup(item.type)!,
                        })
                        setDeleteTrigger(true)
                    }}
                    onClick={() => {
                        handleFilterActivate({
                            item,
                            group: getSameFilterGroup(item.type)!,
                        })
                        setDeleteTrigger(true)
                    }}
                />
            ))}
            <Chip
                color='primary'
                label='Zvolit filtry'
                onClick={handleOpen}
                icon={<Tune fontSize='small' />}
            />
        </Stack>
    ) : null
}

export const BulletinFilters: FC<BulletinFilterHookProps> = memo(
    wrap(BulletinFiltersView, useBulletinFilters),
)
