import { useRef, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDocumentMutation } from '../../../api'
import { useCustomSnackbar } from '../../../hooks'
import { useHelpCamera } from '../../../hooks/useHelpCamera'

export const useDocumentUpload = () => {
    const { visitId } = useParams<{ visitId: string }>()
    const documentMutation = useDocumentMutation(visitId)
    const { cameraPermission } = useHelpCamera()
    const [fileIsUploading, setFileIsUploading] = useState(false)

    const [fileData, setFileData] = useState<any>(null)
    const [description, setDescriptionValue] = useState<string>('')
    const [hasDescription, setHasDescription] = useState<boolean | null>(null)

    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const customSnackbar = useCustomSnackbar()

    const setDescription = useCallback((descriptionValue: string) => {
        setDescriptionValue(descriptionValue)
    }, [])

    const handleSendUpload = async (hasDescriptionParam: boolean) => {
        if (!fileData) return

        const sendFileData = {
            ...fileData,
            description: hasDescriptionParam ? description : '',
        }

        setFileIsUploading(true) // Start loading
        try {
            await documentMutation.mutateAsync(sendFileData)
        } finally {
            setFileIsUploading(false) // End loading
        }

        setHasDescription(null)
        setDescriptionValue('')
    }

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]

        if (!file) return
        setFileIsUploading(true) // Start loading

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            const fileData = {
                name: file.name.split('.')[0],
                filename: file.name,
                data: reader.result as string,
            }

            try {
                await documentMutation.mutateAsync(fileData)
            } finally {
                setFileIsUploading(false) // End loading
            }

            /* TODO odkomentovat až budeme dělat description /*
            const fileData = {
                name: file.name.split('.')[0],
                filename: file.name,
                data: reader.result as string,
            }
            // if file is an jpeg jpg png
            if (
                file.type === 'image/jpeg' ||
                file.type === 'image/jpg' ||
                file.type === 'image/png'
            ) {
                setHasDescription(false)
                setFileData(fileData)
            } else {
                documentMutation.mutateAsync(fileData)
            }
            */
        }
    }

    const handleButtonClick = async () => {
        if (cameraPermission === 'denied') {
            customSnackbar(
                'Nebyl udělen přístup k Vaší kameře. V případě, že budete chtít vložit obrázek, vyberte jej z galerie nebo v nastavení Vašeho telefonu udělte prohlížeči náležité oprávnění.',
                'warning',
                true,
            )
        }

        fileInputRef.current?.click()
    }

    return {
        handleFileUpload,
        uploadLoading: fileIsUploading,
        handleButtonClick,
        description,
        setDescription,
        hasDescription,
        handleSendUpload,
        setHasDescription,
        fileInputRef,
    }
}
