import { Stack, Typography } from '@mui/material'
import type { VoucherPlanTextProps } from '../../types'
import { LongTextFormatted } from '../../../../types'

export const VoucherPlanText = (props: VoucherPlanTextProps) => {
    const { formattedText, title } = props

    const getLongTextLink = (text: LongTextFormatted) =>
        text.isTextLonger
            ? text.showFullText
                ? 'Zobrazit méně'
                : 'Zobrazit více'
            : ''

    return !!formattedText?.displayedText ? (
        <Stack
            paddingBottom={2}
            spacing={0.5}
        >
            <Typography
                fontWeight='bold'
                fontSize='13px'
            >
                {title}
            </Typography>
            <Typography fontSize='13px'>
                {formattedText.displayedText}
                {formattedText.isTextLonger && (
                    <Typography
                        fontSize={'inherit'}
                        paddingLeft={1}
                        color='primary'
                        variant='caption'
                        onClick={formattedText.toggleText}
                        sx={{ cursor: 'pointer' }}
                    >
                        {getLongTextLink(formattedText)}
                    </Typography>
                )}
            </Typography>
        </Stack>
    ) : null
}
