import {
    Avatar,
    AvatarGroup,
    Box,
    CircularProgress,
    Paper,
    Stack,
    Typography,
} from '@mui/material'
import { Modal } from '..'
import { useOpenState } from '../../hooks'
import { twMerge } from 'tailwind-merge'
import { BulletinReadabilityProps } from '../../types/components'
import { useUIDSeed } from 'react-uid'

export const BulletinReadability = (props: BulletinReadabilityProps) => {
    const { countOfUsersToRead, readerUsers } = props
    const numberFormulaCount =
        readerUsers &&
        countOfUsersToRead &&
        (readerUsers.totalItems / countOfUsersToRead) * 100

    const { isOpen, handleClose, handleOpen } = useOpenState()

    const uid = useUIDSeed()

    return (
        <Paper
            className={twMerge(
                'cursor-pointer !border-[0.75px] border-solid !border-[#F8833A] px-[10px] shadow-none',
                !readerUsers?.data.length && 'cursor-not-allowed',
            )}
            onClick={!!readerUsers?.data.length ? handleOpen : undefined}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={0.5}
                    className='py-1'
                >
                    <Typography className='text-sm'>Zobrazeno:</Typography>
                    <Box className='relative inline-flex'>
                        <CircularProgress
                            size={33}
                            variant='determinate'
                            color='primary'
                            value={numberFormulaCount}
                            thickness={4}
                            sx={{ strokeLinecap: 'round' }}
                        />
                        <Box className='absolute inset-0 flex flex-col items-center justify-center'>
                            <Typography
                                variant='caption'
                                component='div'
                                className='text-sm font-medium text-gray-700'
                            >
                                {readerUsers?.totalItems}/{countOfUsersToRead}
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
                <AvatarGroup
                    color='primary'
                    max={4}
                    className='items-center'
                    componentsProps={{
                        additionalAvatar: {
                            className:
                                'bg-orange-400 w-[35px] h-[35px] text-white-reduced text-base',
                        },
                    }}
                >
                    {readerUsers?.data?.map((user) => (
                        <Avatar
                            key={uid(user.fullName)}
                            alt={user.fullName || ''}
                            src={user.icon || ''}
                            className='h-[35px] w-[35px] bg-orange-400 text-base text-white-reduced'
                        >
                            {!user?.icon?.trim() &&
                                `${user?.fullName?.split(' ')[0][0]}${
                                    user?.fullName?.split(' ')[1][0]
                                }`}
                        </Avatar>
                    ))}
                </AvatarGroup>
            </Stack>
            <Modal
                open={isOpen}
                handleClose={handleClose}
                title='Uživatelé, kteří zprávu přečetli'
            >
                <Stack>
                    {readerUsers?.data?.map((user) => (
                        <Stack
                            key={uid(user.fullName)}
                            direction='row'
                            alignItems='center'
                            spacing={1}
                            className='py-1'
                        >
                            <Avatar
                                alt={user.fullName || ''}
                                src={user.icon || ''}
                                className='h-[35px] w-[35px] bg-orange-400 text-base text-white-reduced'
                            >
                                {!user.icon?.trim() &&
                                    `${user.fullName?.split(' ')[0][0]}${
                                        user.fullName?.split(' ')[1][0]
                                    }`}
                            </Avatar>
                            <Stack>
                                <Typography className='text-sm leading-[auto]'>
                                    {user.fullName}
                                </Typography>
                                <Typography className='text-xs font-light text-gray-500'>
                                    {user.role}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            </Modal>
        </Paper>
    )
}
