import axios from 'axios'
import {
    Bulletin,
    BulletinCount,
    BulletinFilterUser,
    BulletinMessage,
} from '../../types/components'
import { getRestApiConfig } from '../config'
import {
    BulletinArguments,
    BulletinDeleteProps,
    BulletinFilterQueries,
    BulletinFnolUpdateProps,
    BulletinMultipleArchiveProps,
    BulletinMultipleDeleteProps,
    BulletinMultipleReadProps,
    BulletinUpdateProps,
} from './types'
import { toQueryString } from '../../helpers'

const filterUndefinedValues = (
    queries: BulletinFilterQueries,
): { [key: string]: any } => {
    return Object.entries(queries)
        .filter(([_, value]) => value !== undefined)
        .reduce((acc, [key, value]) => {
            acc[key] = value
            return acc
        }, {} as { [key: string]: any })
}

export const getNotifications = async (
    props: BulletinArguments,
): Promise<Bulletin[]> => {
    const { token, queries } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const filteredQueries = filterUndefinedValues(queries || {})

    const endpointUrl = new URL(
        `/api-mob/Bulletin${
            Object.keys(filteredQueries).length
                ? `?${toQueryString(filteredQueries)}`
                : ''
        }`,
        baseUrl,
    ).href

    const response = await axios.get<Bulletin[]>(endpointUrl, headerConfig)

    return response.data
}

export const getNotification = async (
    token?: string,
    id?: string,
): Promise<BulletinMessage> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Bulletin/${id}`, baseUrl).href

    const response = await axios.get<BulletinMessage>(endpointUrl, headerConfig)

    return response.data
}

export const getNotificationsCount = async (
    token?: string,
): Promise<BulletinCount> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL('/api-mob/BulletinCount', baseUrl).href

    const response = await axios.get<BulletinCount>(endpointUrl, headerConfig)

    return response.data
}

export const putNotification = async (props: BulletinUpdateProps) => {
    const { token, id, ...rest } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Bulletin/${id}`, baseUrl).href

    const response = await axios.put(endpointUrl, rest, headerConfig)

    return response.data
}

export const deleteNotification = async ({
    id,
    token,
}: BulletinDeleteProps) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Bulletin/${id}`, baseUrl).href

    return await axios.delete(endpointUrl, headerConfig)
}

export const deleteMultipleNotifications = async ({
    ids,
    token,
}: BulletinMultipleDeleteProps) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Bulletin/multiple/delete`, baseUrl)
        .href

    return await axios.delete(endpointUrl, {
        ...headerConfig,

        data: ids,
    })
}

export const archiveMultipleNotifications = async ({
    ids,
    archiveStatus,
    token,
}: BulletinMultipleArchiveProps) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Bulletin/multiple/archive`, baseUrl)
        .href

    return await axios.put(endpointUrl, { ids, archiveStatus }, headerConfig)
}

export const readMultipleNotifications = async ({
    ids,
    token,
}: BulletinMultipleReadProps) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Bulletin/multiple/read`, baseUrl).href

    return await axios.put(endpointUrl, ids, headerConfig)
}

export const postNotification = async (
    props: Omit<BulletinUpdateProps, 'id'>,
) => {
    const { token, ...rest } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/Bulletin`, baseUrl).href
    const response = await axios.post(endpointUrl, rest, headerConfig)
    return response.data
}

export const postFnolNotification = async (
    props: Omit<BulletinFnolUpdateProps, 'id'>,
) => {
    const { token, ...rest } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(`/api-mob/BulletinFnol`, baseUrl).href

    const response = await axios.post(endpointUrl, rest, headerConfig)

    return response.data
}

export const getAvailableFilterUsers = async (
    token?: string,
): Promise<BulletinFilterUser[]> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/BulletinFilters/BulletinUsers`,
        baseUrl,
    ).href

    const response = await axios.get(endpointUrl, headerConfig)

    return response.data
}
