import {
    Badge,
    Box,
    CircularProgress,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { FC } from 'react'
import {
    Contacts,
    ImportantNoteModal,
    Patient,
    History,
    TabPanel,
    Visit,
    VisitActions,
    MainVisitAction,
    VisitTabs,
    Medication,
} from './components'
import type { VisitDetailPageProps } from './types'
import { useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'
import moment from 'moment'
import { Navigation } from '../../components'
import StickyNoteIcon from '@mui/icons-material/StickyNote2Rounded'
import { NotesModal } from './components/Patient/NotesModal'
import HomeIcon from '@mui/icons-material/Home'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

export const VisitDetailView: FC<VisitDetailPageProps> = (props) => {
    const {
        activeTab,
        handleTabChange,
        activePatient,
        visitData,
        visitDetailsLoading,
        importantNoteModal,
        importantNotesQuery,
        patientDocumentsQuery,
        patientNotesQuery,
        handleFileUpload,
        uploadLoading,
        handleButtonClick,
        fileInputRef,
        visitForm,
        patientHistory,
        intersectElement,
        mainVisit,
        isTimeClicked,
        setIsTimeClicked,
        unreadNotes,
        noteModal,
        newNoteModal,
        newNoteForm,
        showTabs,
        description,
        setDescription,
        hasDescription,
        setHasDescription,
        handleSendUpload,
        isScrollUpVisible,
    } = props

    const navigate = useNavigate()
    const { visitId } = useParams()

    const handleActionClick = () => {
        navigate(`/visit-detail/${mainVisit?.id}`)
    }

    const mainVisitDate =
        mainVisit?.date && moment(mainVisit.date, 'DD.MM.YYYY').format('DD.MM.')

    const unreadedNotes = patientNotesQuery.data?.data.filter(
        (item) => !item.readedByLoginUser,
    )

    const handleNoteModalOpen = () => noteModal.handleOpen()

    return (
        <Box
            component='main'
            className={classnames(
                'flex min-h-[calc(100vh-64px)] flex-col bg-orange-50 pt-[0.7rem]',
                {
                    'pb-8': mainVisit && mainVisit.id !== Number(visitId),
                },
            )}
        >
            <Navigation className='max-h-[5.75rem]'>
                <Stack
                    direction='row'
                    alignItems='center'
                >
                    <IconButton
                        onClick={() => navigate('/home')}
                        className='mr-1'
                    >
                        <HomeIcon style={{ color: '#faa26b' }} />
                    </IconButton>
                    <Typography
                        variant='h1'
                        className='text-xl font-medium sm:text-2xl'
                    >
                        {visitDetailsLoading
                            ? 'Načítám návštěvu...'
                            : visitData?.patient.fullName}
                    </Typography>
                    <IconButton onClick={handleNoteModalOpen}>
                        <Badge
                            overlap='circular'
                            color={
                                unreadedNotes?.length !== 0
                                    ? 'error'
                                    : undefined
                            }
                            badgeContent={unreadedNotes?.length}
                        >
                            {patientNotesQuery.isLoading ? (
                                <CircularProgress size='inherit' />
                            ) : (
                                <StickyNoteIcon fontSize='inherit' />
                            )}
                        </Badge>
                    </IconButton>
                    <NotesModal
                        noteModal={noteModal}
                        newNoteModal={newNoteModal}
                        patientNotesQuery={patientNotesQuery}
                        newNoteForm={newNoteForm}
                    />
                </Stack>
            </Navigation>
            {!!mainVisit && mainVisit.id !== Number(visitId) && (
                <MainVisitAction
                    handleActionClick={handleActionClick}
                    mainVisitDate={mainVisitDate}
                />
            )}
            {!importantNotesQuery.isLoading &&
                !importantNotesQuery.isError &&
                !!importantNotesQuery.data.data.length && (
                    <ImportantNoteModal
                        importantNotesQuery={importantNotesQuery}
                        importantNoteModal={importantNoteModal}
                    />
                )}
            <VisitTabs
                activeTab={activeTab}
                handleTabChange={handleTabChange}
                patientHistory={patientHistory}
                intersectElement={intersectElement}
                unreadNotes={unreadNotes}
                showTabs={showTabs}
            />
            <Box className='container mx-auto pb-[4rem] lg:mt-4 lg:max-w-container'>
                <TabPanel
                    currentTab={activeTab}
                    tabName={0}
                >
                    <form>
                        <Visit
                            visitData={visitData}
                            visitDetailsLoading={visitDetailsLoading}
                            visitForm={visitForm}
                            isTimeClicked={isTimeClicked}
                            setIsTimeClicked={setIsTimeClicked}
                            showTabs={showTabs}
                        />
                        <VisitActions
                            visitData={visitData}
                            {...visitForm}
                        />
                    </form>
                </TabPanel>
                <TabPanel
                    currentTab={activeTab}
                    tabName={1}
                >
                    <Patient
                        uploadLoading={uploadLoading}
                        handleButtonClick={handleButtonClick}
                        fileInputRef={fileInputRef}
                        patientNotesQuery={patientNotesQuery}
                        description={description}
                        setDescription={setDescription}
                        hasDescription={hasDescription}
                        setHasDescription={setHasDescription}
                        handleFileUpload={handleFileUpload}
                        handleSendUpload={handleSendUpload}
                        activePatient={activePatient}
                        isLoading={visitDetailsLoading}
                        patientDocumentsQuery={patientDocumentsQuery}
                        showTabs={showTabs}
                    />
                </TabPanel>
                <TabPanel
                    currentTab={activeTab}
                    tabName={2}
                >
                    <Contacts patientId={activePatient?.id} />
                </TabPanel>
                <TabPanel
                    currentTab={activeTab}
                    tabName={3}
                >
                    <History
                        handleTabChange={handleTabChange}
                        {...patientHistory}
                    />
                </TabPanel>
                <TabPanel
                    currentTab={activeTab}
                    tabName={4}
                >
                    <Medication
                        visitId={visitId}
                        dateAt={visitData?.plannedVisitTime}
                    />
                </TabPanel>
            </Box>
            <IconButton
                className={classnames(
                    'fixed bottom-4 right-4 bg-[#f8833b] text-[#fff] transition-opacity duration-200 hover:bg-[#f8833b]',
                    {
                        'opacity-0': !isScrollUpVisible,
                        'opacity-100': isScrollUpVisible,
                    },
                )}
                onClick={() =>
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
                }
            >
                <ArrowUpwardIcon />
            </IconButton>
        </Box>
    )
}
