import { memo } from 'react'
import { Modal } from '../../../components'
import type { BulletinFilterModalProps } from '../types'
import { BulletinFilterGroup } from './BulletinFilterGroup'
import { useUIDSeed } from 'react-uid'
import { Button, Stack } from '@mui/material'

export const BulletinFilterModal = memo((props: BulletinFilterModalProps) => {
    const {
        open,
        handleClose,
        bulletinFilters,
        handleFilterActivate,
        resetFilterGroup,
        handleUseFilters,
        resetFilterAllActiveGroup,
    } = props

    const uid = useUIDSeed()
    return (
        <Modal
            open={open}
            handleClose={handleClose}
            title='Filtry nástěnky'
        >
            <>
                {bulletinFilters.map((filterData) => (
                    <BulletinFilterGroup
                        key={uid(filterData.title)}
                        handleFilterActivate={handleFilterActivate}
                        resetFilterGroup={resetFilterGroup}
                        {...filterData}
                    />
                ))}
                <Stack className='flex-row justify-end gap-2'>
                    <Button
                        type='button'
                        variant='outlined'
                        size='medium'
                        className='bg-white-reduced text-sm normal-case shadow-none'
                        onClick={() => {
                            resetFilterAllActiveGroup(true)
                        }}
                    >
                        zrušit filtry
                    </Button>
                    <Button
                        variant='contained'
                        size='medium'
                        type='submit'
                        onClick={() => handleUseFilters(handleClose)}
                        className='text-sm normal-case shadow-none'
                    >
                        Použít filtry
                    </Button>
                </Stack>
            </>
        </Modal>
    )
})
