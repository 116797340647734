import { useState, useEffect } from 'react'
import {
    PatientDocumentForm,
    PatientDocumentHookProps,
    PatientDocumentProps,
} from '../types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
    useChangeDocumentInfoMutation,
    useChangeDocumentRotationMutation,
} from '../../../../../api'

export const usePatientDocument = ({
    name,
    patientId,
    documentId,
    refetchImage,
    closeFileNameModal,
}: PatientDocumentProps): PatientDocumentHookProps => {
    const [rotation, setRotation] = useState(0)
    const changeDocumentInfoMutation = useChangeDocumentInfoMutation(patientId)

    const changeDocumentRotationMutation =
        useChangeDocumentRotationMutation(patientId)

    const [initialLoad, setIntialLoad] = useState<boolean>(true)
    const [imgStyles, setImageStyles] = useState({
        maxWidth: '90vw',
        maxHeight: '90vh',
    })

    useEffect(() => {
        if (!initialLoad) {
            setImageStyles({
                maxWidth: rotation === 90 || rotation === 270 ? '90vh' : '90vw',
                maxHeight:
                    rotation === 90 || rotation === 270 ? '90vw' : '90vh',
            })
        }
    }, [rotation, initialLoad])

    const defaultValues: PatientDocumentForm = {
        name: name,
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required('Je nutné vyplnit název dokumentu'),
    })

    const formMethods = useForm<PatientDocumentForm>({
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const handleRotate = () => {
        setIntialLoad(false)
        setRotation((prevRotation) => {
            return (prevRotation + 90) % 360
        })
    }

    const submitChangeRotation = async (rotation: number) => {
        try {
            if (rotation === 0 || rotation === 360) {
                return
            }

            const sendData = {
                rotation,
                documentId: documentId,
                patientId: patientId,
            }

            await changeDocumentRotationMutation(sendData)
            refetchImage()
            setRotation(0)
        } catch (error) {
            console.log(error)
        }
    }

    const submitMessage = async (data: PatientDocumentForm) => {
        try {
            const sendData = {
                filename: data.name,
                documentId: documentId,
                patientId: patientId,
            }

            await changeDocumentInfoMutation(sendData)
            closeFileNameModal && closeFileNameModal()
        } catch (error) {
            console.log(error)
        }
    }

    return {
        rotation,
        setRotation,
        formMethods,
        submitMessage,
        submitChangeRotation,
        handleRotate,
        imgStyles,
    }
}
