import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function VisitSavingModal(props: UseHelpModalProps) {
    return (
        <Modal {...props}>
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        className='mb-4 text-center font-bold'
                        color={'primary'}
                    >
                        Uložení a odeslání návštěvy
                    </Typography>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Uložit:
                    </Typography>
                    <Typography className='text-sm'>
                        Návštěva se uloží do stavu Rozpracovaná.
                    </Typography>
                </Stack>
                <Stack className='gap-1'>
                    <Typography
                        component='h2'
                        className='text-base font-semibold'
                    >
                        Uložit a odeslat:
                    </Typography>
                    <Typography className='text-sm'>
                        Po vyplnění všech povinných polí lze návštěvu odeslat ke
                        schválení.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default VisitSavingModal
