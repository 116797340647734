import axios from 'axios'
import { NewNoteFormProps } from '../../pages/VisitDetailPage/types'
import type {
    ChangeDocumentFilenameProps,
    ChangeDocumentRotationProps,
    PatientNote,
} from '../../types'
import { getRestApiConfig } from '../config'
import type {
    ContactCollection,
    DocumentCollection,
    NotesCollection,
} from './types'
import { ListMedication, MedicationProps, PostDocumentProps } from '../types'
import { toQueryString } from '../../helpers'

export const getPatient = async (patientId?: string, token?: string) => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(`/api-mob/patient/${patientId}`, baseUrl).href

    const response = await axios.get<ContactCollection>(
        endpointUrl,
        headerConfig,
    )
    return response.data
}

export const getPatientContacts = async (
    patientId?: number,
    token?: string,
): Promise<ContactCollection> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(
        `/api-mob/patientcontacts/${patientId}`,
        baseUrl,
    ).href

    const response = await axios.get<ContactCollection>(
        endpointUrl,
        headerConfig,
    )
    return response.data
}

export const getPatientNotes = async (
    patientId?: number,
    token?: string,
    selectImportant?: boolean | null,
): Promise<NotesCollection> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const getImportant = selectImportant ? 'selectImportant=true' : ''
    const endpointUrl = new URL(
        `/api-mob/patientnotes/${patientId}?${getImportant}`,
        baseUrl,
    ).href

    const response = await axios.get<NotesCollection>(endpointUrl, headerConfig)
    return response.data
}

export const createPatientNote = async (
    patientId?: number,
    data?: NewNoteFormProps,
    token?: string,
): Promise<PatientNote> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(`/api-mob/patientnotes/${patientId}`, baseUrl)
        .href

    const body = {
        text: data?.newNote,
        type: data?.noteType,
    }

    const response = await axios.post<PatientNote>(
        endpointUrl,
        body,
        headerConfig,
    )
    return response.data
}

export const updatePatientNote = async (
    token?: string,
    noteId?: number,
    readedByLoginUser?: boolean,
): Promise<PatientNote> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(`/api-mob/patientnotes/${noteId}`, baseUrl).href

    const body = {
        readedByLoginUser: readedByLoginUser || true,
    }

    const response = await axios.put<PatientNote>(
        endpointUrl,
        body,
        headerConfig,
    )

    return response.data
}

export const getPatientDocuments = async (
    patientId?: number,
    token?: string,
): Promise<DocumentCollection> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(
        `/api-mob/patientdocuments/${patientId}`,
        baseUrl,
    ).href

    const response = await axios.get<DocumentCollection>(
        endpointUrl,
        headerConfig,
    )
    return response.data
}

export const postPatientDocument = async (props: PostDocumentProps) => {
    const { file, patientId, token, visitID, description } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(
        `/api-mob/patientdocuments/${patientId}`,
        baseUrl,
    ).href

    const body = {
        ...file,
        description,
        visitID,
    }

    const response = await axios.post(endpointUrl, body, headerConfig)

    return response.data
}

export const getDocumentFile = async (
    documentId?: string,
    token?: string,
    width?: number,
    height?: number,
): Promise<string> => {
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/document/${documentId}/?${width ? `width=${width}` : ''}&${
            height ? `height=${height}` : ''
        }`,
        baseUrl,
    ).href

    const response = await axios.get<string>(endpointUrl, headerConfig)
    return response.data
}

export const getPatientMedications = async (
    props: MedicationProps,
): Promise<ListMedication> => {
    const { token, patientId, ...query } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)

    const endpointUrl = new URL(
        `/api-mob/PatientMedications/${patientId}?${toQueryString({
            ...query,
        })}`,
        baseUrl,
    ).href

    const response = await axios.get<ListMedication>(endpointUrl, headerConfig)

    return response.data
}

export const putPatientDocumentInfo = async (
    props: ChangeDocumentFilenameProps,
    token?: string,
): Promise<ChangeDocumentFilenameProps> => {
    const { patientId, filename, documentId } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(
        `/api-mob/PatientDocumentInfo/${patientId}/${documentId}`,
        baseUrl,
    ).href

    const body = {
        filename: filename,
    }

    const response = await axios.put<ChangeDocumentFilenameProps>(
        endpointUrl,
        body,
        headerConfig,
    )

    return response.data
}

export const putPatientDocumentRotation = async (
    props: ChangeDocumentRotationProps,
    token?: string,
): Promise<ChangeDocumentRotationProps> => {
    const { patientId, rotation, documentId } = props
    const { baseUrl, headerConfig } = getRestApiConfig(token)
    const endpointUrl = new URL(
        `/api-mob/PatientDocumentRotation/${patientId}/${documentId}`,
        baseUrl,
    ).href

    const response = await axios.put<ChangeDocumentRotationProps>(
        endpointUrl,
        rotation,
        headerConfig,
    )

    return response.data
}
