import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useOpenState } from '../../../hooks'
import type {
    BulletinFilterHookProps,
    BulletinFilterItems,
    BulletinFilterProps,
    BulletinFilterType,
    FlatActiveFilter,
} from '../types'
import { BulletinFiltersContext } from '../../../providers'
import { useBulletinUsers } from './useBulletinUsers'
import { useBulletinFilterReset } from './useBulletinFilterReset'
import { useBulletinFiltersActivate } from './useBulletinFiltersActivate'
import { getFilters } from '../constants/filters'
import { useFnol } from '../../../providers/AuthProvider/hooks'

export const OLOMOUC_FNOL_ID = 38

export const useBulletinFilters = (
    props: BulletinFilterHookProps,
): BulletinFilterProps => {
    const filterModalState = useOpenState()

    const { isFnol } = useFnol()

    const staticFilters = useMemo(() => getFilters(isFnol), [isFnol])

    const [bulletinFilters, setBulletinFilters] =
        useState<BulletinFilterItems[]>(staticFilters)

    useBulletinUsers({ staticFilters, setBulletinFilters })

    const { setBulletinQueryFilters } = useContext(BulletinFiltersContext)

    const { resetFilterGroup, resetFilterAllActiveGroup } =
        useBulletinFilterReset({
            bulletinFilters,
            setBulletinFilters,
        })

    const {
        handleFilterActivate,
        extractedActiveFiltersWithType: activeFilters,
        handleActiveQueries,
    } = useBulletinFiltersActivate({
        bulletinFilters,
        setBulletinFilters,
        setBulletinQueryFilters,
    })

    useEffect(() => {
        handleActiveQueries()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const [actualFilter, setActualFilter] =
        useState<FlatActiveFilter[]>(activeFilters)

    const [updateFilter, setUpdateFilter] = useState<boolean>(false)

    useEffect(() => {
        if (deleteTrigger) {
            handleActiveQueries()
            setActualFilter(activeFilters)
            setDeleteTrigger(false)
        }
    }, [activeFilters, handleActiveQueries, setActualFilter, deleteTrigger])

    useEffect(() => {
        if (updateFilter) {
            setActualFilter(activeFilters)
            setUpdateFilter(false)
        }
    }, [activeFilters, actualFilter, handleActiveQueries, updateFilter])

    const getSameFilterGroup = useCallback(
        (groupType: BulletinFilterType) => {
            return bulletinFilters.find(({ type }) => type === groupType)
        },
        [bulletinFilters],
    )

    const handleUseFilters = (handleClose: () => void) => {
        handleActiveQueries()
        setUpdateFilter(true)
        handleClose()
    }

    return {
        bulletinFilters,
        setDeleteTrigger,
        actualFilter,
        handleFilterActivate,
        resetFilterGroup,
        filterModalState,
        handleUseFilters,
        setUpdateFilter,
        resetFilterAllActiveGroup,
        activeFilters,
        handleActiveQueries,
        getSameFilterGroup,
        ...props,
    }
}
