import { RefObject, useState } from 'react'
import { useUpdateNoteMutation } from '../../../api'
import { useIntersectionObserver } from '../../../hooks'

interface UseNoteVisibilityProps {
    noteId: number
    isReaded: boolean
    noteRef: RefObject<HTMLDivElement>
}

export const useNoteVisibility = async (props: UseNoteVisibilityProps) => {
    const { noteId, isReaded, noteRef } = props
    const [isOnceIntersected, setIsOnceIntersectected] = useState(false)

    const updateNoteMutation = useUpdateNoteMutation(noteId, true)
    const isIntersecting = useIntersectionObserver({
        ref: noteRef,
        options: {
            rootMargin: '20px',
        },
    })

    if (!isOnceIntersected && isIntersecting && !isReaded) {
        await updateNoteMutation.mutateAsync()
        setIsOnceIntersectected(true)
    }
}
