import { Stack, Typography } from '@mui/material'
import Modal from '../Modal'
import { UseHelpModalProps } from './types'

function MedicationNoteModal(props: UseHelpModalProps) {
    return (
        <Modal {...props}>
            <Stack className='gap-3'>
                <Stack className='gap-1'>
                    <Typography
                        className='mb-4 text-center font-bold'
                        color={'primary'}
                    >
                        Poznámka k medikaci
                    </Typography>
                    <Typography className='text-sm'>
                        Poznámka zapsaná na kartě Odbornost - Medikace.
                    </Typography>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default MedicationNoteModal
