import { useCallback, useContext, useState } from 'react'
import { useBulletinListQuery } from '../../../api'
import { BulletinContentHook, BulletinPageContentProps } from '../types'
import { AuthContext, BulletinFiltersContext } from '../../../providers'
import { useCustomSnackbar } from '../../../hooks'
import { Bulletin, EDIT_NOTIFICATIONS_ENUM } from '../../../types/components'
import {
    useArchiveMultipleNotifications,
    useDeleteMultipleNotifications,
    useReadMultipleNotifications,
} from '../../../api/notifications/hooks'

export const useBulletinContent = (
    props: BulletinContentHook,
): BulletinPageContentProps => {
    const { bulletinQueryFilters } = useContext(BulletinFiltersContext)

    const bulletinQuery = useBulletinListQuery({ ...bulletinQueryFilters })

    const { user } = useContext(AuthContext)

    const { mutateBulletinMultipleDelete } = useDeleteMultipleNotifications()
    const { mutateBulletinMultipleRead } = useReadMultipleNotifications()
    const { mutateBulletinMultipleArchive } = useArchiveMultipleNotifications()

    const [selectedCards, setSelectedCards] = useState<number[]>([])
    const snackbar = useCustomSnackbar()

    const handleSelectAll = useCallback(() => {
        const selectedBulletinIds = bulletinQuery?.bulletinList?.map(
            (bulletin) => bulletin.id,
        )

        if (!selectedBulletinIds || !bulletinQuery?.bulletinList) return

        if (selectedCards.length === bulletinQuery.bulletinList.length) {
            setSelectedCards([])
        } else {
            setSelectedCards(selectedBulletinIds)
        }
    }, [bulletinQuery.bulletinList, selectedCards])

    const handleCardSelect = useCallback((id: number) => {
        setSelectedCards((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((cardId) => cardId !== id)
                : [...prevSelected, id],
        )
    }, [])

    const handleReadSelectedNotifications = useCallback(
        async (selectedBulletins: Bulletin[]) => {
            const selectedBulletinIds = selectedBulletins
                .filter((bulletin) => !bulletin.isReaded)
                .map((selectedBulletin) => selectedBulletin.id)

            if (selectedBulletinIds.length === 0) {
                snackbar('Vybrané zprávy jsou již přečteny', 'success')
                return
            }

            await mutateBulletinMultipleRead({ ids: selectedBulletinIds })
        },
        [mutateBulletinMultipleRead, snackbar],
    )

    const handleArchiveSelectedNotifications = useCallback(
        async (selectedBulletins: Bulletin[]) => {
            // Implementace pro archivaci

            if (!(user?.isAdmin || user?.isHeadNurse)) {
                snackbar(
                    'Nelze archivovat zprávy bez oprávnění vrchní sestry nebo administrátora',
                    'error',
                )
                return
            }

            const selectedBulletinIds = selectedBulletins
                .filter((bulletin) => !bulletin.isArchived)
                .map((selectedBulletin) => selectedBulletin.id)

            if (selectedBulletinIds.length === 0) {
                snackbar('Vybrané zprávy jsou již archivované', 'success')
                return
            }

            await mutateBulletinMultipleArchive({
                ids: selectedBulletinIds,
                archiveStatus: true,
            })
        },
        [
            mutateBulletinMultipleArchive,
            user?.isAdmin,
            user?.isHeadNurse,
            snackbar,
        ],
    )

    const handleUnArchiveSelectedNotifications = useCallback(
        async (selectedBulletins: Bulletin[]) => {
            // Implementace pro archivaci

            if (!(user?.isAdmin || user?.isHeadNurse)) {
                snackbar(
                    'Nelze archivovat zprávy bez oprávnění vrchní sestry nebo administrátora',
                    'error',
                )
                return
            }

            const selectedBulletinIds = selectedBulletins
                .filter((bulletin) => bulletin.isArchived)
                .map((selectedBulletin) => selectedBulletin.id)

            if (selectedBulletinIds.length === 0) {
                snackbar('Vybrané zprávy jsou již aktivní', 'success')
                return
            }

            await mutateBulletinMultipleArchive({
                ids: selectedBulletinIds,
                archiveStatus: false,
            })
        },
        [
            mutateBulletinMultipleArchive,
            user?.isAdmin,
            user?.isHeadNurse,
            snackbar,
        ],
    )

    const handleDeleteSelectedNotifications = useCallback(
        async (selectedBulletins: Bulletin[]) => {
            // Implementace pro smazání

            const adminMessages = selectedBulletins.filter((bulletin) => {
                return bulletin.isAdmin
            })

            if (adminMessages.length > 0) {
                snackbar('Nelze smazat administrační zprávy', 'error')
                return
            }

            const fnolMessages = selectedBulletins.filter((bulletin) => {
                return bulletin.tag === 'Fnol'
            })

            if (fnolMessages.length > 0) {
                snackbar('Nelze smazat FNOL zprávy', 'error')
                return
            }

            const hasOtherPeopleMessages = selectedBulletins.some(
                (bulletin) => {
                    return bulletin.user.id !== user?.id
                },
            )

            if (
                hasOtherPeopleMessages &&
                !(user?.isAdmin || user?.isHeadNurse)
            ) {
                snackbar(
                    'Nelze smazat zprávy jiných osob bez oprávnění vrchní sestry nebo administrátora',
                    'error',
                )
                return
            }

            const selectedBulletinIds = selectedBulletins.map(
                (selectedBulletin) => selectedBulletin.id,
            )

            await mutateBulletinMultipleDelete({ ids: selectedBulletinIds })
        },
        [
            mutateBulletinMultipleDelete,
            snackbar,
            user?.id,
            user?.isHeadNurse,
            user?.isAdmin,
        ],
    )
    const handleMultipleNotificationsAction = useCallback(
        (action: EDIT_NOTIFICATIONS_ENUM) => {
            const selectedBulletins = bulletinQuery?.bulletinList?.filter(
                (bulletin) => selectedCards.some((id) => id === bulletin.id),
            )

            if (!selectedBulletins || selectedBulletins.length === 0) {
                snackbar('Je nutné vybrat alespoň jednu zprávu', 'error')
                return
            }

            switch (action) {
                case EDIT_NOTIFICATIONS_ENUM.Archive:
                    handleArchiveSelectedNotifications(selectedBulletins)
                    break
                case EDIT_NOTIFICATIONS_ENUM.UnArchive:
                    handleUnArchiveSelectedNotifications(selectedBulletins)
                    break
                case EDIT_NOTIFICATIONS_ENUM.Delete:
                    handleDeleteSelectedNotifications(selectedBulletins)
                    break
                case EDIT_NOTIFICATIONS_ENUM.Read:
                    handleReadSelectedNotifications(selectedBulletins)
                    break
            }
        },
        [
            bulletinQuery?.bulletinList,
            selectedCards,
            snackbar,
            handleArchiveSelectedNotifications,
            handleUnArchiveSelectedNotifications,
            handleDeleteSelectedNotifications,
            handleReadSelectedNotifications,
        ],
    )

    return {
        bulletinQuery,
        bulletinQueryFilters,
        selectedCards,
        handleSelectAll,
        handleCardSelect,
        handleMultipleNotificationsAction,
    }
}
