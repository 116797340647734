import { Button, ButtonProps } from '@mui/base'
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import { Box, CircularProgress, Typography } from '@mui/material'

export interface DashboardButtonProps extends ButtonProps {
    countNumber?: number
    countLoading: boolean
    label: string
}

export const DashboardButton = (props: DashboardButtonProps) => {
    const { countNumber = 0, label, countLoading, ...elementProps } = props

    const numberFormulaCount = (countNumber > 15 ? 1 : countNumber / 15) * 100

    const getNumberColor = () => {
        if (numberFormulaCount > 50) {
            return 'error'
        } else if (numberFormulaCount > 25) {
            return 'warning'
        } else {
            return 'success'
        }
    }

    return (
        <Button
            className='flex w-full cursor-pointer flex-col items-center justify-center gap-3 rounded border border-solid border-orange-500 bg-white-reduced p-5 text-gray-700'
            {...elementProps}
        >
            {countLoading && (
                <CircularProgress
                    color='primary'
                    size={54}
                />
            )}
            {!countLoading && countNumber === 0 && (
                <SentimentSatisfiedAltOutlinedIcon
                    color='success'
                    fontSize='large'
                    sx={{ width: 54, height: 54 }}
                />
            )}
            {!countLoading && countNumber > 0 && (
                <Box className='relative inline-flex'>
                    <CircularProgress
                        size={54}
                        variant='determinate'
                        className='absolute inset-0 flex items-center justify-start text-white-reduced'
                        value={100}
                        thickness={5}
                    />
                    <CircularProgress
                        size={54}
                        variant='determinate'
                        color={getNumberColor()}
                        value={numberFormulaCount}
                        thickness={5}
                        sx={{ strokeLinecap: 'round' }}
                    />
                    <Box className='absolute inset-0 flex flex-col items-center justify-center'>
                        <Typography
                            variant='caption'
                            component='div'
                            className='font-medium text-gray-700'
                            sx={{ fontSize: 16 }}
                        >
                            {countNumber}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Typography className='font-medium'>{label}</Typography>
        </Button>
    )
}

/*  PŘEDPŘIPRVAENÁ NOVÁ VERZE 
export const DashboardButton = (props: DashboardButtonProps) => {
    const { countNumber = 0, label, countLoading, ...elementProps } = props

    const numberFormulaCount = (countNumber > 15 ? 1 : countNumber / 15) * 100

    const getNumberColor = () => {
        if (numberFormulaCount > 50) {
            return 'error'
        } else if (numberFormulaCount > 25) {
            return 'warning'
        } else {
            return 'success'
        }
    }

    return (
        <Button
            className='text-white flex h-full w-full cursor-pointer flex-col items-center justify-between gap-3 rounded border border-solid border-orange-500 bg-[#F8F8F8] px-3  py-2 text-orange-500 hover:bg-[#F8F8F8]'
            {...elementProps}
        >
            {countLoading && (
                <CircularProgress
                    color='primary'
                    size={54}
                />
            )}
            {!countLoading && countNumber === 0 && (
                //<SentimentSatisfiedAltOutlinedIcon
                  //  color='success'
                 //   fontSize='large'
                //    sx={{ width: 54, height: 54 }}
                ///>
                //
                <></>
            )}
            {!countLoading && countNumber > 0 && (
                <Box className='relative inline-flex'>
                    <CircularProgress
                        size={54}
                        variant='determinate'
                        className='absolute inset-0 flex items-center justify-start text-white-reduced'
                        value={100}
                        thickness={5}
                    />
                    <CircularProgress
                        size={54}
                        variant='determinate'
                        color={getNumberColor()}
                        value={numberFormulaCount}
                        thickness={5}
                        sx={{ strokeLinecap: 'round' }}
                    />
                    <Box className='absolute inset-0 flex flex-col items-center justify-center'>
                        <Typography
                            variant='caption'
                            component='div'
                            className='font-medium text-gray-700'
                            sx={{ fontSize: 16 }}
                        >
                            {countNumber}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Typography className='font-medium'>{label}</Typography>
        </Button>
    )
}
*/
