import { Box, IconButton, Modal, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import type { MouseEvent } from 'react'
import type { ModalComponentProps } from '../types/components'

const AppModal = (props: ModalComponentProps) => {
    const {
        title,
        children,
        closeButton = true,
        handleClose,
        ...elementProps
    } = props
    return (
        <Modal
            onClick={handleClose}
            {...elementProps}
        >
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    minHeight: '20vh',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    borderRadius: '6px',
                    maxWidth: '768px',
                }}
                onClick={(e: MouseEvent) => e.stopPropagation()}
            >
                <Paper
                    sx={{
                        padding: '40px 20px 35px',
                        background: '#feefe6',
                    }}
                >
                    {closeButton && (
                        <Box className='sticky top-5 z-10 -mt-5 justify-end text-right'>
                            <IconButton
                                className='ml-auto bg-orange-400 text-white-reduced'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )}

                    {title && (
                        <Typography
                            className='pb-6 text-center font-medium'
                            textAlign='center'
                            color='primary.main'
                            fontWeight='medium'
                            pb={3}
                        >
                            {title}
                        </Typography>
                    )}
                    {children}
                </Paper>
            </Box>
        </Modal>
    )
}

export default AppModal
