import type { Plan } from '../../../types'
import type { PlanSetter, TreatmentHookProps, TreatmentItem } from '../types'
import { useState, type MouseEvent, useEffect, useCallback } from 'react'

export const useTreatmentItem = (props: TreatmentHookProps): TreatmentItem => {
    const {
        visitForm,
        diagnose,
        carePlanIndex,
        allowSelectAllPlans,
        plans,
        voucherCarePlans,
    } = props

    const {
        formMethods: { watch, setValue },
        carePlanMethods: { fields, update },
    } = visitForm

    const [allStepsDone, setAllStepsDone] = useState(false)

    const diagnoseName =
        diagnose?.name &&
        diagnose?.name.charAt(0).toUpperCase() + diagnose?.name.slice(1)

    const watchPlans = watch(`carePlans.${carePlanIndex}.plans`)
    const watchVouchers = watch(`carePlans.${carePlanIndex}.voucherCarePlans`)

    const countNumber =
        (watchPlans?.filter((plan) => plan?.done)?.length || 0) +
        (watchVouchers?.filter((voucher) => voucher?.done)?.length || 0)

    const numberFormulaCount =
        (countNumber /
            ((plans?.length || 0) + (voucherCarePlans?.length || 0))) *
        100

    const getNumberColor = () => {
        if (numberFormulaCount > 75) {
            return 'success'
        } else if (numberFormulaCount > 50) {
            return 'warning'
        } else {
            return 'error'
        }
    }

    const currentPlans = watch(`carePlans.${carePlanIndex}.plans`)

    const checkAllDone = (treatmentPlans: Plan[] | undefined): boolean =>
        !!treatmentPlans && treatmentPlans?.every((plan) => plan.done)

    const getDonePlan = (plan: Plan): Plan => ({ ...plan, done: true })
    const getClearPlan = (plan: Plan): Plan => ({ ...plan, done: false })

    const setPlansDoneValue = (planSetter: PlanSetter) => {
        // const plansWithNewValue = currentPlans?.map((plan: Plan) => planSetter(plan))
        currentPlans?.forEach((plan: Plan, index: number) => {
            return setValue(
                `carePlans.${carePlanIndex}.plans.${index}`,
                planSetter(plan),
            )
        })
    }

    const handleSelectAll = (e: MouseEvent) => {
        e.stopPropagation()
        if (!allowSelectAllPlans) return

        setPlansDoneValue(allStepsDone ? getClearPlan : getDonePlan)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setAllStepsDone(checkAllDone(currentPlans))
    })

    return {
        ...props,
        allStepsDone,
        handleSelectAll,
        getNumberColor,
        diagnoseName,
        numberFormulaCount,
        countNumber,
    }
}
