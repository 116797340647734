import { Close, NearMeRounded, PhoneEnabledRounded } from '@mui/icons-material'
import {
    Box,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'
import { MouseEvent } from 'react'
import { Modal, VisitCard } from '../../../components'
import type { CarouselItemProps } from '../types'
import { useLongText, useOpenState } from '../../../hooks'
import { useNavigationStatsMutation, usePhoneStatsMutation } from '../../../api'

export const CarouselItem = (props: CarouselItemProps) => {
    const {
        isLoading,
        latLng,
        patientPhone,
        nurseNote,
        patientAddress,
        id,
        date,
        slidesPerPage,
        visitCardClick,
        hasPermissionToOpen,
        nurseName,
        hasDate = false,
        ...elementProps
    } = props

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const phoneUsageStat = usePhoneStatsMutation()
    const navigationUsageStat = useNavigationStatsMutation()

    const { isOpen, handleClose, stateSwitch } = useOpenState()

    const handleLocationButtonClick = async (e: MouseEvent) => {
        e.preventDefault()
        await navigationUsageStat.mutateAsync()

        let url
        if (latLng === '') {
            url = `https://www.google.com/maps/place/${patientAddress?.latitude},${patientAddress?.longitude}`
        } else {
            url = `https://www.google.com/maps/dir/${latLng}/${patientAddress?.latitude},${patientAddress?.longitude}`
        }

        window.open(url, '_blank')
    }

    const { displayedText, isTextLonger } = useLongText({
        text: nurseNote,
        maxLength: 44,
        customTextLength: 29,
    })

    const handlePhoneButtonClick = async (e: MouseEvent) => {
        e.preventDefault()
        await phoneUsageStat.mutateAsync()
        if (!!patientPhone) {
            window.location.href = `tel:${patientPhone}`
        }
        if (!patientPhone) {
            enqueueSnackbar(`Telefon neuveden - není možné zavolat.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        }
    }

    return (
        <Grid
            xs={12 / slidesPerPage}
            item
            {...elementProps}
        >
            <Box>
                <Box className='relative'>
                    <VisitCard
                        hasDate={hasDate}
                        mainVisitData={{ id, date }}
                        onCardClick={visitCardClick}
                        {...props}
                    />
                    <Stack
                        className='absolute -bottom-2 -right-2 gap-2'
                        direction='row'
                    >
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                width={68}
                                height={68}
                                className='rounded'
                                animation='wave'
                            />
                        ) : (
                            <IconButton
                                onClick={handleLocationButtonClick}
                                className='h-[3.0625rem] w-[3.0625rem] rounded bg-orange-300 py-4 text-white-reduced transition-colors hover:bg-orange-400'
                            >
                                <NearMeRounded className='text-3xl' />
                            </IconButton>
                        )}
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                width={68}
                                height={68}
                                className='rounded'
                                animation='wave'
                            />
                        ) : (
                            <IconButton
                                onClick={handlePhoneButtonClick}
                                className={classnames(
                                    'h-[3.0625rem] w-[3.0625rem] rounded bg-orange-300 py-4 text-white-reduced transition-colors hover:bg-orange-400',
                                    {
                                        'bg-gray-500 opacity-50 focus:bg-gray-500':
                                            !!!patientPhone,
                                    },
                                )}
                            >
                                <PhoneEnabledRounded className='text-3xl' />
                            </IconButton>
                        )}
                    </Stack>
                </Box>
            </Box>
            {!isLoading && !!nurseNote?.length && (
                <Grid
                    item
                    xs={12}
                >
                    <Box className='border-0 border-b border-solid border-orange-200 pb-3 pt-4'>
                        <Typography className='px-2 text-left text-[13px]'>
                            {displayedText}
                            {isTextLonger && (
                                <Typography
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        stateSwitch()
                                    }}
                                    component='span'
                                    className='inline-block cursor-pointer pl-2 text-[13px] font-bold text-orange-300 transition-colors hover:text-orange-400 lg:pl-0'
                                >
                                    Zobrazit více
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                </Grid>
            )}
            {!isLoading && !!nurseNote?.length && (
                <Modal
                    open={isOpen}
                    onClose={handleClose}
                    handleClose={handleClose}
                    title='Poznámka'
                >
                    <Typography
                        sx={{
                            backgroundColor: '#f8f8f8',
                            borderRadius: '5px',
                            fontSize: '13px',
                            padding: '10px',
                        }}
                    >
                        {nurseNote}
                    </Typography>
                </Modal>
            )}
        </Grid>
    )
}
