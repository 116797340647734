import {
    Box,
    Button,
    CircularProgress,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import type { TreatmentItem } from '../../types'
import { useMemo, type FC } from 'react'

export const TreatmentItemView: FC<TreatmentItem> = (props) => {
    const {
        isLoading,
        diagnose,
        visitForm,
        plans,
        voucherCarePlans,
        allowSelectAllPlans,
        carePlanIndex,
        isThinner,
        hasBackground,
        hideSelectAll = false,
        isError = false,
        canEdit,
        canSave,
        getNumberColor,
        numberFormulaCount,
        handleSelectAll,
        countNumber,
        diagnoseName,
        allStepsDone,
        hasAllCodes = false,
        ...elementProps
    } = props

    const itemCodes = useMemo(() => {
        const codes = voucherCarePlans?.map((item) => item.code)

        if (hasAllCodes) {
            return !!codes
                ? codes?.length > 1
                    ? codes?.join(', ')
                    : codes?.[0]
                : 'počet výkonů: 0'
        }

        if (!diagnose?.code && !!codes && codes?.length > 1) {
            return ''
        }

        return diagnose?.code || voucherCarePlans?.[0].code || ''
    }, [diagnose?.code, hasAllCodes, voucherCarePlans])

    return (
        <Stack
            direction='row'
            alignItems='center'
            bgcolor={isError ? '#EE7777' : hasBackground ? '#f8f8f8' : ''}
            borderRadius={2}
            overflow='hidden'
            marginX={'-8px'}
            padding={'8px'}
            justifyContent={
                !!diagnose && !hideSelectAll ? 'space-between' : 'flex-start'
            }
            spacing={isThinner ? 1.7 : 3}
            className='cursor-pointer'
            {...elementProps}
        >
            <Box
                position='relative'
                display='inline-flex'
            >
                {isLoading ? (
                    <Skeleton
                        variant='circular'
                        width={54}
                        height={54}
                        animation='wave'
                    />
                ) : (
                    <>
                        <CircularProgress
                            size={54}
                            variant='determinate'
                            sx={{
                                color: 'rgb(248, 248, 248)',
                                strokeLinecap: 'round',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                            value={100}
                            thickness={4}
                        />
                        <CircularProgress
                            size={54}
                            variant='determinate'
                            color={getNumberColor()}
                            value={numberFormulaCount}
                            thickness={4}
                            sx={{ strokeLinecap: 'round' }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                        >
                            <Typography
                                variant='caption'
                                component='div'
                                sx={{
                                    fontWeight: 'medium',
                                    color: isError
                                        ? '#f8f8f8'
                                        : 'rgb(51, 51, 51)',
                                    fontSize: 14,
                                }}
                            >
                                {countNumber}/
                                {(plans?.length || 0) +
                                    (voucherCarePlans?.length || 0)}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Stack
                sx={{
                    width: diagnose && !hideSelectAll ? '154px' : 'unset',
                }}
            >
                {isLoading ? (
                    <>
                        <Skeleton
                            height={12}
                            width={35}
                            animation='wave'
                        />
                        <Skeleton
                            height={14}
                            width={154}
                            animation='wave'
                        />
                    </>
                ) : (
                    <>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'medium',
                                color: isError ? '#f8f8f8' : 'rgb(51, 51, 51)',
                            }}
                        >
                            {diagnoseName ||
                                ((voucherCarePlans?.length || 0) > 1
                                    ? `Výkon bez diagnózy č. ${
                                          carePlanIndex + 1
                                      }`
                                    : voucherCarePlans?.[0]?.name || '')}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'light',
                                color: isError ? '#f8f8f8' : 'rgb(51, 51, 51)',
                            }}
                        >
                            {itemCodes}
                        </Typography>
                    </>
                )}
            </Stack>
            {isLoading ? (
                <Skeleton
                    variant='rounded'
                    width={68}
                    height={32}
                />
            ) : (
                hideSelectAll === false &&
                diagnose &&
                allowSelectAllPlans && (
                    <Button
                        variant='contained'
                        size='small'
                        disabled={!canSave}
                        sx={{
                            color: 'rgb(248, 248, 248)',
                            textTransform: 'lowercase',
                            boxShadow: 'none',
                            width: '12ch',
                            paddingX: 0.5,
                            fontSize: 12,
                        }}
                        onClick={handleSelectAll}
                    >
                        {allStepsDone ? 'odebrat' : 'vybrat'} vše
                    </Button>
                )
            )}
        </Stack>
    )
}
