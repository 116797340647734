import { Box, InputLabel, OutlinedInput, SpeedDialAction } from '@mui/material'
import { PatientTabProps } from '../../types'
import { PatientDocuments } from './PatientDocuments'
import { SplitContent } from './SplitContent'
import { uid } from 'react-uid'
import { HelpDial } from '../../../../components'
import { visitDetailPatientActions } from '../../../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../../../components/HelpDial/types'

export const Patient = (props: PatientTabProps) => {
    const {
        activePatient,
        isLoading,
        patientDocumentsQuery,
        handleFileUpload,
        uploadLoading,
        handleButtonClick,
        fileInputRef,
        patientNotesQuery,
        description,
        setDescription,
        setHasDescription,
        handleSendUpload,
        hasDescription,
        showTabs,
    } = props

    return (
        <>
            <SplitContent
                showTabs={showTabs}
                isLoading={isLoading}
                activePatient={activePatient}
                patientNotesQuery={patientNotesQuery}
            />

            <Box className='flex flex-wrap gap-4'>
                <Box
                    flex={1}
                    minWidth={300}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Interní poznámka
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        readOnly
                        size='small'
                        minRows={5}
                        maxRows={8}
                        value={activePatient?.note}
                        multiline
                        className='mb-6 rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                    />
                </Box>
                <Box
                    flex={1}
                    minWidth={300}
                >
                    <InputLabel className='pb-2 text-sm font-medium'>
                        Poznámka pro terénní sestru
                    </InputLabel>
                    <OutlinedInput
                        fullWidth
                        readOnly
                        size='small'
                        minRows={5}
                        maxRows={8}
                        value={activePatient?.nurseNote}
                        multiline
                        className='mb-6 rounded bg-orange-100 px-2 py-1 text-sm font-medium'
                    />
                </Box>
            </Box>

            <PatientDocuments
                handleButtonClick={handleButtonClick}
                fileInputRef={fileInputRef}
                handleFileUpload={handleFileUpload}
                description={description}
                hasDescription={hasDescription}
                setHasDescription={setHasDescription}
                handleSendUpload={handleSendUpload}
                setDescription={setDescription}
                patientDocumentsQuery={patientDocumentsQuery}
                uploadLoading={uploadLoading}
                patientId={activePatient?.id}
            />
            <HelpDial>
                {({ handleActionClick }) => {
                    return visitDetailPatientActions.map(
                        ({ name, icon, modalName }: HelpSpeedDialActions) => (
                            <SpeedDialAction
                                key={uid(modalName)}
                                icon={icon}
                                tooltipTitle={name}
                                tooltipPlacement='right'
                                tooltipOpen
                                sx={{
                                    '& .MuiSpeedDialAction-staticTooltipLabel':
                                        {
                                            width: 'max-content',
                                        },
                                }}
                                onClick={handleActionClick(modalName)}
                            />
                        ),
                    )
                }}
            </HelpDial>
        </>
    )
}
