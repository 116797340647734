import { SetStateAction, Dispatch, useCallback, useEffect } from 'react'
import { useFilterUsersQuery } from '../../../api/notifications/hooks'
import { BulletinFilterItems, FilterItemValue } from '../types'

export const useBulletinUsers = ({
    staticFilters,
    setBulletinFilters,
}: {
    staticFilters: BulletinFilterItems[]
    setBulletinFilters: Dispatch<SetStateAction<BulletinFilterItems[]>>
}) => {
    const {
        data: filterUsers,
        isLoading: filterUsersLoading,
        isError: filterUsersError,
    } = useFilterUsersQuery()

    const handleUsersToFilter = useCallback(() => {
        if (!filterUsersLoading && !filterUsersError) {
            if (filterUsers.length > 1) {
                const formattedUserItems: FilterItemValue[] = filterUsers
                    .map((user) => ({
                        active: false,
                        name: user.fullName || '',
                        value: String(user.id),
                        icon: user.icon,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))

                const formattedUserGroup: BulletinFilterItems = {
                    title: 'Tým',
                    type: 'users',
                    items: formattedUserItems,
                }
                setBulletinFilters([...staticFilters, formattedUserGroup])
            } else {
                setBulletinFilters((prev) => {
                    const usersFilterIndex = prev.findIndex(
                        (filter) => filter.type === 'users',
                    )

                    if (usersFilterIndex !== -1) {
                        prev.splice(usersFilterIndex, 1)
                    }

                    return [...prev]
                })
            }
        }
    }, [
        filterUsers,
        filterUsersError,
        filterUsersLoading,
        setBulletinFilters,
        staticFilters,
    ])

    useEffect(() => {
        if (filterUsers) {
            handleUsersToFilter()
        }
    }, [handleUsersToFilter, filterUsers])
}
